import {CalendarCompliance, CalendarComplianceDate, CalendarTypeEnum} from "../../../../api/grs";

export interface CalendarComplianceBasic {
    id: number;
    name: string;
    type: CalendarTypeEnum;
    compliant: CalendarComplianceDate;
}

export function getLatestCompliance(
    data: CalendarCompliance[] | null | undefined
): CalendarComplianceBasic[] {
    const output: CalendarComplianceBasic[] = [];
    if (!data || data.length === 0) return output;

    for (const compliance of data) {
        if (compliance.archived) continue;
        if (!compliance.compliant || compliance.compliant.length === 0) continue;
        let compliant = compliance.compliant[0];

        for (const compDate of compliance.compliant) {
            if (compDate.date < compliant.date) compliant = compDate;
        }

        output.push({
            id: compliance.id,
            name: compliance.name,
            type: compliance.type,
            compliant
        });
    }

    return output;
}
