import React from "react";
import {CalendarEventItem} from "../../../store/fullCalendarItemList/actions/FullCalendarItemListActionTypes";
import moment, {Moment} from "moment";
import {AssignmentState, StaffAssignment} from "../../../api/grs";

const WeekViewDay = (props: WeekViewDayProps) => {
    const getClassForWeekViewDayItem = () => {
        if (!props.entry) {
            const now = moment().startOf("day");

            if (now.unix() === props.startTime) {
                return "week-view-day today";
            }
            return "week-view-day";
        }

        return "week-view-day";
    };

    const getAssignmentClassName = (assignmentState: AssignmentState, isHistoric: boolean) => {
        if (isHistoric) {
            return "week-view-day briefing-accepted";
        }
        switch (assignmentState) {
            case AssignmentState.Accepted:
                return "week-view-day accepted";
            case AssignmentState.BriefingAccepted:
                return "week-view-day briefing-accepted";
            case AssignmentState.Unassigned:
                return "week-view-day unallocated";
            case AssignmentState.WaitingAccept:
                return "week-view-day assigned";
            default:
                return "";
        }
    };

    /** Provides callback to event being clicked*/
    const onCellClicked = () => {
        const args: WeekViewDayClickArgs = {
            entry: props.entry,
            startTime: props.startTime
        };
        props.onEventClick(args);
    };

    const className = () => {
        if (!props.entry) return "";
        const {entryState, isHistoric} = props.entry;
        const assignmentClass = getAssignmentClassName(entryState, isHistoric);
        const weekDayItemClass = getClassForWeekViewDayItem();

        return `week-view-calendar-cell week-view-calendar-cell-width week-view-calendar-item ${weekDayItemClass} ${assignmentClass}`;
    };

    return (
        <React.Fragment>
            {props.entry ? (
                <td
                    data-date={props.startTime}
                    onClick={onCellClicked}
                    className={className()}
                    valign="top"
                >
                    <p className="mb-1 header-font font-weight-bolder text-wrap">
                        {props.entry.title}
                    </p>
                    {props.entry.assignments.map((assignment: StaffAssignment, index: number) => {
                        return (
                            <p className="mb-1 week-view-details text-wrap" key={index}>
                                <span className="assignees">
                                    {assignment.staffMember?.staffName}
                                </span>
                            </p>
                        );
                    })}
                    <p className="mb-2 week-view-details text-wrap text-break">
                        {props.entry.timeText}
                    </p>
                </td>
            ) : (
                <td
                    data-date={props.startTime}
                    onClick={onCellClicked}
                    className={`week-view-calendar-cell week-view-calendar-cell-width week-view-calendar-item ${getClassForWeekViewDayItem()}`}
                />
            )}
        </React.Fragment>
    );
};

export default WeekViewDay;

interface WeekViewDayProps {
    entry?: CalendarEventItem;
    startTime: number;
    currentWeek: Moment;
    onEventClick: (args: WeekViewDayClickArgs) => void;
}

export interface WeekViewDayClickArgs {
    entry?: CalendarEventItem;
    startTime: number;
}
