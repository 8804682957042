import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import moment, {Moment} from "moment";
import {useHistory} from "react-router-dom";
import {useQuery} from "../../../../Hooks/useQuery";
import {AvailableShiftsRequestUi} from "../../../../../api/grs";
import {buildAvailableShiftEntryListRequest} from "../Helpers/availableShiftsListCalendar";
import {RequestFilterProps} from "../../../../Filters/helpers/filterHelpers";
import DatePickerInputButton from "../../../../Button/DatePickerInputButton";

const AvailableShiftsCalendarFilters = (props: RequestFilterProps<AvailableShiftsRequestUi>) => {
    const [startDate, setStartDate] = useState<Moment>();
    const history = useHistory();
    const query = useQuery();

    /** Selected when the date changes */
    const onDateChanged = (date: Date | null) => {
        if (!date) return;

        setStartDate(moment(date));
    };

    /** Upon mounting, see if we can get query string exists, if so, set date from this. */
    useEffect(() => {
        const month = query.get("month");
        if (month) {
            setStartDate(moment.unix(+month));
            return;
        }
        setStartDate(moment());
    }, []);

    /** Watches for changes to start date */
    useEffect(() => {
        if (!startDate) return;
        const request = buildAvailableShiftEntryListRequest(startDate, history);

        props.onRequestChanged(request);
    }, [startDate]);

    return (
        <React.Fragment>
            <table className="filters-table mt-3">
                <tbody className="filters-lg">
                    <tr>
                        <th className="filter-heading w-50 pl-3 pr-3">Select Date</th>
                    </tr>
                    <td className="filter-heading w-50 pl-3 pr-3">
                        {startDate && (
                            <DatePicker
                                selected={startDate.toDate()}
                                onChange={onDateChanged}
                                showMonthYearPicker
                                dateFormat="MMMM yyyy"
                                portalId="root-portal"
                                customInput={<DatePickerInputButton />}
                            />
                        )}
                    </td>
                </tbody>
                <tbody className="filters-md">
                    <tr>
                        <th className="filter-heading w-50 pl-3 pr-3">Select Date</th>
                    </tr>
                    <td className="filter-heading w-50 pl-3 pr-3">
                        {startDate && (
                            <DatePicker
                                selected={startDate.toDate()}
                                onChange={onDateChanged}
                                showMonthYearPicker
                                dateFormat="MMMM yyyy"
                                portalId="root-portal"
                                customInput={<DatePickerInputButton />}
                            />
                        )}
                    </td>
                </tbody>
                <tbody className="filters-sm">
                    <tr>
                        <th className="filter-heading w-100 pl-3 pr-3">Select Date</th>
                    </tr>
                    <td className="filter-heading w-100 pl-3 pr-3">
                        {startDate && (
                            <DatePicker
                                selected={startDate.toDate()}
                                onChange={onDateChanged}
                                showMonthYearPicker
                                dateFormat="MMMM yyyy"
                                portalId="root-portal"
                                customInput={<DatePickerInputButton />}
                            />
                        )}
                    </td>
                </tbody>
            </table>
        </React.Fragment>
    );
};

export default AvailableShiftsCalendarFilters;
