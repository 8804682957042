import {Calendar, ClinicalGrade, StaffAssignmentGrouping} from "../../../../api/grs";
import {GenericNameValueToggle} from "../../../../utils/sortingUtils";
import {showErrorToast} from "../../../../utils/toastUtils";

/** Checks to see if the Calendar form is valid. Returns validity and any error messages. */
export function isCalendarFormValid(calendar: Calendar): boolean {
    let valid = true;

    //Check to see if the calendar name is invalid
    if (calendar.name.length < 3) {
        valid = false;
        showErrorToast("Calendar name must contain 3 or more characters.");
    }

    //Check for if any section names are invalid
    for (const section of calendar.staffBlock.sections) {
        if (section.name.length < 3) {
            valid = false;
            showErrorToast(
                "One or more sections have invalid names. Section names must contain 3 or more characters."
            );
            break;
        }
    }

    //Check for if any group names in a section are invalid
    for (const section of calendar.staffBlock.sections) {
        for (const group of section.groupings) {
            if (group.name.length < 3) {
                valid = false;
                showErrorToast(
                    `A group in the section '${section.name}' is invalid. Group names must contain 3 or more characters.`
                );
                break;
            }
        }
    }

    return valid;
}

/** This updates the Staff Assignment Grouping Clinical grades and returns updated state for the checkbox */
export function updateStaffGroupingClinicalGrades(
    details: StaffAssignmentGrouping,
    toggleValue: GenericNameValueToggle
) {
    const selectedGrade = getClinicalGradeFromString(toggleValue.value);

    // User selected the value, add it to the list
    if (!toggleValue.checked) {
        // Already present, do nothing
        if (details.clinicalGrades.includes(selectedGrade)) return details;

        // Not present, add and return
        return {
            ...details,
            clinicalGrades: [...details.clinicalGrades, selectedGrade]
        };
    } else {
        // User deselected the value, remove it from the list
        const newGrades = details.clinicalGrades.filter((x) => x !== selectedGrade);
        return {
            ...details,
            clinicalGrades: newGrades
        };
    }
}

/** Gets Clinical Grade from string */
export function getClinicalGradeFromString(value: string): ClinicalGrade {
    return ClinicalGrade[value as keyof typeof ClinicalGrade];
}
