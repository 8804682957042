import React, {ReactNode, useState} from "react";
import {
    CalendarEntry,
    CalendarSummary,
    CalendarTypeEnum,
    StaffInterestQueryResponse,
    StaffLink,
    UserInterest,
    Venue
} from "../../../../api/grs";
import {useSelector} from "react-redux";
import {RootStore} from "../../../../store/Store";
import {UserData} from "../../../../api/staff";
import ClinicalGradeBadge from "./ClinicalGradeBadge";
import AcceptRegisterRequest from "../../AvailableShiftsList/Components/Actions/AcceptRegisterRequest";
import {canUserBeAssignedByGrade} from "../../AvailableShiftsList/Helpers/availableShiftsListHelpers";
import {formatUnixToDateTime} from "../../../../utils/momentUtils";
import moment from "moment";
import {Link} from "react-router-dom";

export interface StaffInterestListWidgetProps {
    data: StaffInterestQueryResponse | null | undefined;
}

interface StaffInterestItem {
    user: UserInterest;
    calendar: CalendarSummary | null | undefined;
    entry: CalendarEntry;
    venue: Venue | null;
    staffMember: UserData | null;
}

export default function StaffInterestListWidget(props: StaffInterestListWidgetProps) {
    const venueList = useSelector((state: RootStore) => state.venueList.data || []);
    const users = useSelector((state: RootStore) => state.staffList.data || []).toMap("username");
    if (!props.data || props.data.interestList.length === 0) return <EmptyStaffList />;
    const records = getStaffInterestRecords(props.data, venueList, users);

    return (
        <div className="widget-staff-interest">
            <div className="widget-staff-interest-inner">
                <h2>Staff Availability</h2>
                <div className="dash-widget-subtitle">Next 4 weeks</div>

                <div className="widget-staff-interest-list">
                    {records.map((item) => (
                        <React.Fragment key={`${item.user.user.staffId}-${item.entry.id}`}>
                            <ShiftInterestRow item={item} />
                            <div className="widget-staff-interest-spacer"></div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
}

function EmptyStaffList() {
    return (
        <div className="widget-staff-interest">
            <div className="widget-staff-interest-inner">
                <h2>Staff Availability</h2>
                <div className="dash-widget-subtitle">Next 4 weeks</div>

                <p className="widget-staff-interest-no-interest">
                    No slots with staff pending interest
                </p>
            </div>
        </div>
    );
}

interface ShiftInterestRowProps {
    item: StaffInterestItem;
}

function ShiftInterestRow(props: ShiftInterestRowProps) {
    const key = `${props.item.user.user.staffId}-${props.item.entry.id}`;
    const staffName = getStaffName(props.item.user.user, props.item.staffMember);
    const clinicalGrade = props.item.staffMember?.clinicalGrade;
    const locationName = getLocationName(props.item);
    const section = getSectionName(props.item);
    const date = formatUnixToDateTime(props.item.entry.startDate);
    const link = getEditLink(props.item.entry);
    const [show, setShow] = useState<boolean>(true);

    if (!show) return null;

    return (
        <div className="widget-staff-interest-item" key={key}>
            <div className="widget-staff-interest-item-details">
                <div className="widget-staff-interest-item-staffname">
                    {staffName}
                    <ClinicalGradeBadge clinicalGrade={clinicalGrade} />
                </div>
                <div className="widget-staff-interest-item-rows">
                    <div className="widget-staff-interest-label">Location:</div>
                    <div className="widget-staff-interest-value">{locationName}</div>

                    <div className="widget-staff-interest-label">Shift details:</div>
                    <div className="widget-staff-interest-value">{section}</div>

                    <div className="widget-staff-interest-label">Shift date:</div>
                    <div className="widget-staff-interest-value">
                        <Link to={link}>{date}</Link>
                    </div>
                </div>
            </div>

            <div className="widget-staff-interest-item-controls">
                <AcceptRegisterRequest
                    interest={props.item.user}
                    entry={props.item.entry}
                    location="entry"
                    hideElement={() => setShow(false)}
                />
            </div>
        </div>
    );
}

function getStaffInterestRecords(
    data: StaffInterestQueryResponse,
    venues: Venue[] | null | undefined,
    users: Map<string | number, UserData> | null | undefined
): StaffInterestItem[] {
    const output: StaffInterestItem[] = [];

    for (const interest of data.interestList) {
        const entry = data.entries.find((thisEntry) => thisEntry.id === interest.entryId);
        if (!entry) continue;
        const staffMember = users?.get(interest.user.staffId);
        const canBeAssigned = canUserBeAssignedByGrade(entry, interest, staffMember?.clinicalGrade);
        if (!canBeAssigned) continue;
        const calendarSummary = data.calendarSummaries?.find(
            (calendar) => calendar.id === entry.calendarId
        );
        const venue = venues?.find((thisVenue) => thisVenue.id === entry.venueId);

        output.push({
            user: interest,
            calendar: calendarSummary,
            entry: entry,
            venue: venue ?? null,
            staffMember: staffMember ?? null
        });
    }

    output.sort((a, b) => a.entry.startDate - b.entry.startDate);
    return output;
}

function getStaffName(link: StaffLink, userData: UserData | null | undefined): string {
    if (userData) return `${userData.firstName} ${userData.lastName}`;

    return link.staffName;
}

function getLocationName(item: StaffInterestItem): ReactNode {
    switch (item.entry.calendarType) {
        case CalendarTypeEnum.Frontline:
            return <span>{item.entry.description || "Frontline"}</span>;

        default:
            const venueName = item.venue?.name;
            const calendarName = item.calendar?.name;
            if (venueName && calendarName) {
                if (venueName != calendarName) {
                    return (
                        <>
                            <span>{venueName}</span>
                            <span>, {calendarName}</span>
                        </>
                    );
                } else return <span>{venueName}</span>;
            }

            if (venueName) return <span>{venueName}</span>;
            if (calendarName) return <span>{calendarName}</span>;
            return <span>Event</span>;
    }
}

function getSectionName(item: StaffInterestItem): ReactNode {
    return (
        <>
            <span>{item.user.group.name}</span>
        </>
    );
}

function getEditLink(entry: CalendarEntry): string {
    const urlParams = new URLSearchParams();

    const entryDate = moment.unix(entry.startDate);
    urlParams.append("calendarId", entry.calendarId.toString());
    urlParams.append("isGlobalCalendar", "true");

    if (entry.calendarType === CalendarTypeEnum.Frontline) {
        const startOfWeek = entryDate.startOf("week").unix();
        urlParams.append("week", startOfWeek.toString());
    } else {
        const startOfMonth = entryDate.startOf("month").unix();
        urlParams.append("month", startOfMonth.toString());
    }

    return "/calendar/EMS%20Calendar/edit-calendar-entry/" + entry.id + "?" + urlParams.toString();
}
