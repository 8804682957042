import React, {useEffect} from "react";
import {routeNames} from "../../../Navigation/routeNames";
import {useDispatch} from "react-redux";
import {NavigationItem} from "../../../../store/navigation/actions/NavigationActionTypes";
import {setNavigationItems} from "../../../../store/navigation/actions/NavigationActions";
import {usePageUrl} from "../../../Hooks/usePageUrl";
import {useCalendarPageDetails} from "../../../Hooks/useCalendarPageDetails";
import {getCalendarPathForCalendarChildRoutes, getVenuesListPath} from "../../VenueList/VenueList";
import {EventsReportRequest} from "../../../../api/grs";
import {getEventReportForCalendar} from "../../../../store/eventReport/actions/EventReportActions";
import {
    getCalendar,
    nullifyCalendarStore
} from "../../../../store/calendar/actions/CalendarActions";
import moment from "moment";
import EventReportStats from "./Components/EventReportStats";
import EventReportYearGraph from "./Components/EventReportYearGraph";

/** Parent component for the Event report */
const EventReport = () => {
    const {fullPath} = usePageUrl();
    const dispatch = useDispatch();

    const {calendarId, calendarName} = useCalendarPageDetails();

    useEffect(() => {
        if (!calendarId) return;
        const calendarPath = getCalendarPathForCalendarChildRoutes(calendarName, +calendarId);
        const startDate = moment().startOf("year");
        const endDate = startDate.clone().add(1, "year");
        const request: EventsReportRequest = {
            calendarId: +calendarId,
            startDateInclusive: startDate.unix(),
            endDateExclusive: endDate.unix()
        };
        const navItems: NavigationItem[] = [
            {
                name: routeNames.groupRosteringSystem.name,
                path: routeNames.groupRosteringSystem.path
            },
            {
                name: calendarName,
                path: calendarPath,
                children: [
                    {
                        name: routeNames.eventReport.name,
                        path: fullPath
                    },
                    {
                        name: routeNames.venueList.name,
                        superAdminOnly: true,
                        path: getVenuesListPath(calendarName, +calendarId)
                    }
                ]
            }
        ];

        dispatch(setNavigationItems(navItems));
        dispatch(getCalendar(+calendarId));
        dispatch(getEventReportForCalendar(request));

        return function () {
            dispatch(nullifyCalendarStore());
        };
    }, [calendarId, calendarName, dispatch, fullPath]);

    return (
        <div className="dash-container">
            <div className="dash-widgets">
                <EventReportStats />
                <EventReportYearGraph />
            </div>
        </div>
    );
};

export default EventReport;
