import React from "react";
import {BillingType, Calendar, CalendarTypeEnum, StaffBlock} from "../../../../api/grs";
import {useDispatch} from "react-redux";
import {
    saveCalendarToService,
    setCalendar
} from "../../../../store/calendar/actions/CalendarActions";

import {useHistory} from "react-router-dom";
import {routeNames} from "../../../Navigation/routeNames";
import StaffBlockManagement from "../../GlobalStaffBlock/AddEdit/Components/StaffBlock/StaffBlockManagement";
import {ItemChangedAction} from "../../GlobalStaffBlock/AddEdit/Components/StaffBlock/StaffSection";
import {isCalendarFormValid} from "../Helpers/calendarFormHelpers";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import GenericTypeDropdown from "../../../Dropdown/Components/GenericTypeDropdown";
import FormHeader from "../../../Form/FormHeader";
import FormRow from "../../../Form/FormRow";
import {DebounceInput} from "react-debounce-input";
import FormActionContainer from "../../../Form/FormActionContainer";
import {getBillingTypeFromString, getCalenderTypeFromString} from "../../../../utils/enumUtils";
import {showSuccessToast} from "../../../../utils/toastUtils";
import Toggle, {ToggleSize} from "../../../Toggle/Toggle";
import FormRowWithTooltip from "../../../Form/FormRowWithTooltip";
import {IconType} from "../../../Icon/Icon";
import {usePageUrl} from "../../../Hooks/usePageUrl";

/** Form for the ability to mutate a calendar */
const EditCalendarForm = (props: Calendar) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {searchQueries} = usePageUrl();

    /** Cancel any edits and go back to calendar slices */
    const cancel = () => {
        backToSlices();
    };

    /** Saves calendar to service, checks to see if form is valid */
    const saveCalendar = async () => {
        const validForm = isCalendarFormValid(props);
        if (!validForm) return;

        // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/ban-ts-comment
        // @ts-ignore
        const saved: boolean = await dispatch(saveCalendarToService(props));

        if (!saved) return;

        showSuccessToast("Saved Calendar");

        backToSlices();
    };

    /** Goes back to slices */
    const backToSlices = () => {
        history.push({
            pathname: routeNames.groupRosteringSystem.path,
            search: searchQueries
        });
    };

    const onStaffBlockChanged = (staffBlock: ItemChangedAction<StaffBlock>) => {
        dispatch(
            setCalendar({
                ...props,
                staffBlock: staffBlock.newItem
            })
        );
    };

    return (
        <React.Fragment>
            <div className="page-container mt-4 calendar_form">
                <FormHeader headerName={"Calendar Form"} />
                <FormRow rowName={"Calendar Name"} columnDetailClassName={"pl-0 pr-0"}>
                    <DebounceInput
                        debounceTimeout={300}
                        value={props.name}
                        onChange={(event) => {
                            dispatch(
                                setCalendar({
                                    ...props,
                                    name: event.target.value
                                })
                            );
                        }}
                        className="input-fields"
                        placeholder="Enter calendar name here"
                        required={true}
                    />
                </FormRow>
                {props.id === 0 && (
                    <FormRow rowName={"Calendar Type"} columnDetailClassName={"pl-0 pr-0"}>
                        <GenericTypeDropdown
                            enumOptions={CalendarTypeEnum}
                            changeOption={(item) => {
                                if (!item) return;
                                dispatch(
                                    setCalendar({
                                        ...props,
                                        calendarType: getCalenderTypeFromString(item.toString())
                                    })
                                );
                            }}
                            id={props.calendarType}
                            disabled={false}
                            searchable={false}
                            splitByCapitalLetter={false}
                            clearable={false}
                        />
                    </FormRow>
                )}
                <FormRow rowName={"Billing Type"} columnDetailClassName={"pl-0 pr-0"}>
                    <GenericTypeDropdown
                        enumOptions={BillingType}
                        changeOption={(item) => {
                            if (!item) return;
                            dispatch(
                                setCalendar({
                                    ...props,
                                    billingType: getBillingTypeFromString(item.toString())
                                })
                            );
                        }}
                        id={props.billingType}
                        disabled={false}
                        searchable={false}
                        splitByCapitalLetter={false}
                        clearable={false}
                    />
                </FormRow>
                <FormRowWithTooltip
                    tooltipText={`The account code of the calendar to link with Xero Invoicing`}
                    icon={IconType.Question}
                    rowName={"Account Code"}
                    columnDetailClassName={"pl-0 pr-0"}
                >
                    <DebounceInput
                        debounceTimeout={300}
                        value={props.accountCode}
                        onChange={(event) => {
                            dispatch(
                                setCalendar({
                                    ...props,
                                    accountCode: event.target.value
                                })
                            );
                        }}
                        className="input-fields"
                        placeholder="Enter account code here"
                        required={true}
                    />
                </FormRowWithTooltip>
                <FormRowWithTooltip
                    tooltipText={`Archiving the calendar will allow for the calendar to exist 
                        but will not generate stats. The archived calendar will contain historic 
                        data and can be unarchived at any time! 
                        STATUS: ${props.archived ? "ARCHIVED" : "NOT ARCHIVED"}`}
                    icon={IconType.Question}
                    rowName={"Archived?"}
                >
                    <Toggle
                        checked={props.archived}
                        onChange={() => {
                            let archived = props.archived;
                            dispatch(
                                setCalendar({
                                    ...props,
                                    archived: (archived = !archived)
                                })
                            );
                        }}
                        size={ToggleSize.Large}
                    />
                </FormRowWithTooltip>
                <FormRow rowName={"Staff Management"} columnDetailClassName={"pl-0 pr-0"}>
                    <StaffBlockManagement
                        item={props.staffBlock}
                        onItemChanged={onStaffBlockChanged}
                    />
                </FormRow>
                <FormActionContainer>
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue="Save"
                        colour={ButtonColourOptions.Yellow}
                        onClick={saveCalendar}
                        roundedCorner
                    />
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue="Cancel"
                        colour={ButtonColourOptions.DarkBlue}
                        onClick={cancel}
                        roundedCorner
                    />
                </FormActionContainer>
            </div>
        </React.Fragment>
    );
};

export default EditCalendarForm;
