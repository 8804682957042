import React, {ReactNode, useState} from "react";
import {NavBarContext} from "./Components/Sidebar/navContext";
import NavBar from "./Components/Sidebar/NavBar";
import TopBar from "./Components/TopBar/TopBar";
import useMcConfig from "../../../Hooks/useMcConfig";
import {useUserData} from "../../../Hooks/useUserData";
import {StaffAccessLevel, UserData} from "../../../../api/staff";
import {useSelector} from "react-redux";
import {RootStore} from "../../../../store/Store";
import {NavigationItem} from "../../../../store/navigation/actions/NavigationActionTypes";
import {McConfig} from "../../../../McConfigPlugin";
import HeaderTabs from "./Components/Header/HeaderTabs";
import {ShowAdvancedControlsContextProvider} from "../../../../contexts/advancedControls";

export interface LayoutProps {
    compiler?: string;
    framework?: string;
    children: ReactNode;
}

function getPathFromUrl(url: string): string {
    return url.split("?")[0];
}

function getPageTitle(navItems: NavigationItem[], user: UserData, config: McConfig): string {
    const currentPath = decodeURI(window.location.pathname);

    for (const item of navItems) {
        if (!item.path) continue;
        const path = getPathFromUrl(item.path);

        if (path === currentPath) return item.name;

        if (item.children) {
            for (const child of item.children) {
                if (!child.path) continue;
                const childPath = getPathFromUrl(child.path);
                if (childPath === currentPath) return child.name;
            }
        }
    }

    return user.accessLevel === StaffAccessLevel.SystemAdministrator
        ? config.systemDetails.elongatedAdminName
        : config.systemDetails.elongatedStaffName;
}

const MedicareLayout = ({children}: LayoutProps) => {
    const navItems = useSelector((state: RootStore) => state.navigation.data) || [];
    const [showMenu, setShowMenu] = useState(false);
    const {config} = useMcConfig();
    const user = useUserData();
    const title = getPageTitle(navItems, user, config);

    return (
        <ShowAdvancedControlsContextProvider>
            <NavBarContext.Provider value={{show: showMenu, setShow: setShowMenu}}>
                <div className="nav_wrapper">
                    <NavBar />
                    <HeaderTabs />
                    <div className="layout-wrapper" id="main-panel">
                        <TopBar titleText={title} />
                        {children}
                    </div>
                </div>
            </NavBarContext.Provider>
        </ShowAdvancedControlsContextProvider>
    );
};

export default MedicareLayout;
