import React, {useEffect} from "react";
import {WithServiceState} from "store-fetch-wrappers/dist";
import EditCalendarEntryForm from "./Components/EditCalendarEntryForm";
import {useDispatch, useSelector} from "react-redux";
import {useQuery} from "../../Hooks/useQuery";
import {RootStore} from "../../../store/Store";
import {routeNames} from "../../Navigation/routeNames";
import {
    fetchCalendarEntry,
    nullifyCalendarEntryStore
} from "../../../store/calendarEntry/actions/CalendarEntryActions";
import {getCalendar, nullifyCalendarStore} from "../../../store/calendar/actions/CalendarActions";
import {useParams} from "react-router-dom";
import {Calendar} from "../../../api/grs";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {getPathToCalendarFromCalendarEntry} from "./Helpers/calenderEntryHelpers";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {useUserData} from "../../Hooks/useUserData";
import {StaffAccessLevel} from "../../../api/staff";
import {CalendarMonthView} from "../AdminCalendar/Helpers/calendarUtils";
import {usePageUrl} from "../../Hooks/usePageUrl";
import {getVenuesListPath} from "../VenueList/VenueList";
import HeadTabs from "../Layouts/Layout/Components/HeadTabs/HeadTabs";

const ServiceWrapper = WithServiceState(EditCalendarEntryForm);

const EditCalendarEntry = () => {
    const dispatch = useDispatch();
    const params: any = useParams();
    const query = useQuery();
    const user = useUserData();
    const {fullPath} = usePageUrl();
    const calendarId = query.get("calendarId");

    const calendarEntryStore = useSelector((state: RootStore) => state.calendarEntry);
    const calendarStore = useSelector((state: RootStore) => state.calendar);

    useEffect(() => {
        if (!calendarId) return;

        //Create a new calendar entry upon mounting
        (async function getCalendarAndEntry() {
            await dispatch(getCalendar(+calendarId));
            await dispatch(fetchCalendarEntry(params.id));
        })();

        //Clean the stale data from the store.
        return function () {
            dispatch(nullifyCalendarStore());
            dispatch(nullifyCalendarEntryStore());
        };
    }, []);

    useEffect(() => {
        if (user.username.length === 0) return;
        if (calendarStore.loading) return;
        if (!calendarStore.data) return;

        setUpRoutes(calendarStore.data);
    }, [calendarStore.data, calendarStore.loading, user]);

    /** Set up routes for Add Calendar Entry Route */
    const setUpRoutes = (calendar: Calendar) => {
        const calendarUrl = localStorage.getItem("calendar_redirect");
        const calendarPath = calendarUrl
            ? calendarUrl
            : getPathToCalendarFromCalendarEntry(calendar);

        const navigationItems: NavigationItem[] = [
            {
                name: routeNames.groupRosteringSystem.name,
                path: routeNames.groupRosteringSystem.path
            },
            {
                name: getCalendarName(calendar),
                path: calendarPath
            },
            {
                name: routeNames.editCalendarEntry.name,
                path: fullPath,
                tabsOnly: true
            },
            {
                name: routeNames.venueList.name,
                superAdminOnly: true,
                path: getVenuesListPath(calendar.name, calendar.id),
                tabsOnly: true
            }
        ];
        dispatch(setNavigationItems(navigationItems));
    };

    const getCalendarName = (calendar: Calendar) => {
        const isGlobalCalendar = query.get("isGlobalCalendar");
        switch (user.accessLevel) {
            case StaffAccessLevel.SystemAdministrator:
                return isGlobalCalendar ? CalendarMonthView.Global : calendar.name;

            default:
                return calendar.name;
        }
    };

    return (
        <React.Fragment>
            <HeadTabs />
            <ServiceWrapper
                showLoadingText={true}
                loaderType={"overlay"}
                loaderWheelType={"three-ring"}
                placeholderBlockCount={1}
                {...calendarEntryStore}
            />
        </React.Fragment>
    );
};

export default EditCalendarEntry;
