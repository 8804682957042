import React from "react";
import {ClinicalGrade} from "../../../../api/staff";
import {getUiFriendlyClinicalGrade} from "../../../../utils/textUtils";
import paramedics from "./images/paramedics.svg";
import technicians from "./images/technicians.svg";
import first_responders from "./images/first_responders.svg";
import eacs from "./images/eacs.svg";
import nurses from "./images/nurses.svg";

export interface ClinicalGradeBadgeProps {
    clinicalGrade: ClinicalGrade | null | undefined;
}

export default function ClinicalGradeBadge(props: ClinicalGradeBadgeProps) {
    if (!props.clinicalGrade) return null;
    const gradeName = getUiFriendlyClinicalGrade(props.clinicalGrade);

    return (
        <span className="clinical-grade-badge">
            <ClinicalGradeImage grade={props.clinicalGrade} /> {gradeName}
        </span>
    );
}

export interface ClinicalGradeImageProps {
    grade: ClinicalGrade | null | undefined;
}

export function ClinicalGradeImage(props: ClinicalGradeImageProps) {
    if (props.grade === ClinicalGrade.Paramedic)
        return <img src={paramedics} alt="Line art picture of a paramedic" />;
    else if (props.grade === ClinicalGrade.Technician)
        return <img src={technicians} alt="Line art picture of a technician" />;
    else if (props.grade === ClinicalGrade.FirstResponder)
        return <img src={first_responders} alt="Line art picture of a first responder" />;
    else if (props.grade === ClinicalGrade.EAC)
        return <img src={eacs} alt="Line art picture of an EAC" />;
    else if (props.grade === ClinicalGrade.Nurse)
        return <img src={nurses} alt="Line art picture of a nurse" />;
    else return null;
}
