import React, {createContext, ReactNode, useState} from "react";

export interface ShowAdvancedControls {
    show: boolean;
    setShow: (newVal: boolean) => void;
}

export const ShowAdvancedControlsContext = createContext<ShowAdvancedControls | null>(null);

export function ShowAdvancedControlsContextProvider({children}: {children: ReactNode}) {
    const [advanced, setAdvanced] = useState<boolean>(
        localStorage.getItem("showAdvanced") === "true"
    );

    const setAdvancedWrapper = (newVal: boolean) => {
        localStorage.setItem("showAdvanced", newVal.toString());
        setAdvanced(newVal);
    };

    return (
        <ShowAdvancedControlsContext.Provider value={{show: advanced, setShow: setAdvancedWrapper}}>
            {children}
        </ShowAdvancedControlsContext.Provider>
    );
}
