import {InterestedActionItemProp} from "../../Tables/InterestedStaffMembersTable";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../../../store/Store";
import {useState} from "react";
import {
    canUserBeAssigned,
    validateAssignmentInEntry
} from "../../../Helpers/availableShiftsListHelpers";
import {showErrorToast, showSuccessToast} from "../../../../../../utils/toastUtils";
import {
    addStaffAssignment,
    getLocalCalendarEntry,
    removeStaffAssignment,
    saveCalendarEntry,
    setCalendarEntry,
    setStaffAssignment
} from "../../../../../../store/calendarEntry/actions/CalendarEntryActions";
import {
    CalendarEntry,
    DeleteUserInterestRequest,
    LogUserInterestReserve,
    StaffAssignment,
    UserEntriesRequest
} from "../../../../../../api/grs";
import {
    deleteUserInterest,
    reserveUserInterest
} from "../../../../../../store/notifications/registerInterest/actions/RegisterInterestActions";
import {getUserInterestsForCalendarEntry} from "../../../../../../store/userInterest/actions/UserInterestListActions";
import {getStaffInterestForDates} from "../../../../../../store/staffInterestQuery/actions/StaffInterestQueryResponseActions";
import {useShiftOverviewRequestBuilder} from "../../../Hooks/useShiftOverviewRequestBuilder";
import moment from "moment/moment";
import {checkUserEventsOverlap} from "../../../../../../store/overlappingEntriesList/actions/OverlappingEntryListActions";

export function useAcceptRegisterRequest(props: AcceptRegisterRequestProps) {
    const dispatch = useDispatch();
    const {getStaffInterestQueryRequest} = useShiftOverviewRequestBuilder();
    const staffList = useSelector((state: RootStore) => state.staffList.data || []);
    const [loading, setLoading] = useState<boolean>(false);
    const {entry, interest} = props;
    const canBeAssigned = canUserBeAssigned(entry, interest, staffList);

    const acceptUserInterest = async (assignedRate: number, action: InterestedActionItemProp) => {
        setLoading(true);

        const assignment = validateAssignmentInEntry(
            props.entry,
            action.interest,
            assignedRate,
            staffList
        );

        if (!assignment) {
            setLoading(false);
            return;
        }
        await dispatch(setCalendarEntry(props.entry));
        await dispatch(setStaffAssignment(assignment));
        const storeEntry = await dispatch(getLocalCalendarEntry());

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await saveEntry(storeEntry, assignment, action);
    };

    async function acceptUserInterestPart2(
        assignment: StaffAssignment,
        action: InterestedActionItemProp
    ) {
        await dispatch(setCalendarEntry(props.entry));

        await dispatch(setStaffAssignment(assignment));

        const storeEntry = await dispatch(getLocalCalendarEntry());

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await saveEntry(storeEntry, assignment, action);
    }

    const getOverlappingEvents = async (userId: string, entity: CalendarEntry) => {
        const {startDate} = entity;

        const request: UserEntriesRequest = {
            startDate: moment.unix(startDate).clone().startOf("day").unix(),
            endDate: moment.unix(startDate).clone().endOf("day").unix(),
            userId
        };

        try {
            return await dispatch(checkUserEventsOverlap(request));
        } catch (e: any) {
            showErrorToast("Could not check if staff member has overlapping events");
        }
    };

    const saveEntry = async (
        incomingEntry: CalendarEntry,
        assignment: StaffAssignment,
        action: InterestedActionItemProp
    ) => {
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const success: boolean = await dispatch(saveCalendarEntry(incomingEntry));
            if (success) {
                await deleteExistingUserInterest(action);
                showSuccessToast("Staff Member has been assigned!");
                return;
            }
            removeUserFromAssignments(assignment, action);
            setLoading(false);
        } catch (e: any) {
            setLoading(false);
        }
    };

    const removeUserFromAssignments = (
        assignment: StaffAssignment,
        action: InterestedActionItemProp
    ) => {
        dispatch(removeStaffAssignment(assignment.id));
        dispatch(addStaffAssignment(action.interest.group.id));
    };

    const deleteExistingUserInterest = async (action: InterestedActionItemProp) => {
        try {
            const args: DeleteUserInterestRequest = {
                staffId: action.interest.user.staffId,
                entryId: props.entry.id
            };
            await dispatch(deleteUserInterest(args));
            switch (props.location) {
                case "entry":
                    return await getUpdatedEntryUserInterest(action.entry.id);
                case "shifts-list":
                    return await getUpdatedShiftsList();
            }
        } catch (e: any) {
            setLoading(false);
        }
    };

    const onReserveConfirmed = async (action: InterestedActionItemProp) => {
        setLoading(true);
        const args: LogUserInterestReserve = {
            entryId: action.entry.id,
            staffId: action.interest.user.staffId
        };

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(reserveUserInterest(args));

        if (!success) {
            showErrorToast("Could not add user to reserve list");
            setLoading(false);
            return;
        }

        showSuccessToast("Staff Member has been added to reserve list!");

        switch (props.location) {
            case "entry":
                return await getUpdatedEntryUserInterest(action.entry.id);
            case "shifts-list":
                return await getUpdatedShiftsList();
        }
    };

    const getUpdatedEntryUserInterest = async (entryId: number) => {
        await dispatch(getUserInterestsForCalendarEntry(entryId));
        setLoading(false);
    };

    const getUpdatedShiftsList = async () => {
        const {request} = getStaffInterestQueryRequest();
        await dispatch(getStaffInterestForDates(request));
        setLoading(false);
    };

    return {
        loading,
        canBeAssigned,
        acceptUserInterest,
        onReserveConfirmed
    };
}

export interface AcceptRegisterRequestProps extends InterestedActionItemProp {
    location: "entry" | "shifts-list";
    hideElement?: () => void;
}
