import {ClinicalGrade, StaffAccessLevel, UserData} from "../../api/staff";
import {useSelector} from "react-redux";
import {RootStore} from "../../store/Store";

export const useUserData = () => {
    const user: UserData = getEmptyUser();
    const authStore = useSelector((state: RootStore) => state.auth);
    const userFromStore = authStore.data;

    if (userFromStore) return userFromStore;

    return user;
};

export function getEmptyUser(): UserData {
    return {
        paye: false,
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        accessLevel: StaffAccessLevel.Staff,
        clinicalGrade: ClinicalGrade.None,
        avatarUploaded: false,
        jobTitle: ""
    };
}
