import React from "react";
import {CalendarComplianceBasic} from "../Logic/compliance";
import {FaCircleCheck} from "react-icons/fa6";
import {CalendarComplianceLevel} from "../../../../api/grs";
import {HiExclamationTriangle} from "react-icons/hi2";
import {AiFillCloseSquare} from "react-icons/ai";

export interface OverallStatsProps {
    compliance: CalendarComplianceBasic[];
}

export default function OverallStats(props: OverallStatsProps) {
    let filled = 0;
    let notFilled = 0;
    let partFilled = 0;

    for (const compliance of props.compliance) {
        if (compliance.compliant.compliant === CalendarComplianceLevel.Compliant) filled++;
        else if (compliance.compliant.compliant === CalendarComplianceLevel.NotCompliant)
            notFilled++;
        else if (compliance.compliant.compliant === CalendarComplianceLevel.PartialCompliant)
            partFilled++;
    }

    return (
        <div className="widget-top-stats">
            <div className="widget-top-stat compliant">
                <div className="widget-top-stat-heading">
                    <FaCircleCheck />
                    <span>{filled}</span>
                </div>
                <div className="widget-top-stat-description">Filled</div>
            </div>

            <div className="widget-top-stat partialcompliant">
                <div className="widget-top-stat-heading">
                    <HiExclamationTriangle />
                    <span>{partFilled}</span>
                </div>
                <div className="widget-top-stat-description">Partially filled</div>
            </div>

            <div className="widget-top-stat notcompliant">
                <div className="widget-top-stat-heading">
                    <AiFillCloseSquare />
                    <span>{notFilled}</span>
                </div>
                <div className="widget-top-stat-description">Not filled</div>
            </div>
        </div>
    );
}
