import React, {useContext, useState} from "react";
import {StaffAssignmentGrouping, StaffBlockSection} from "../../../../../../../../api/grs";
import {CalendarEntrySettingsContext} from "../../../../../../../Context/CalendarEntrySettingsContext";
import {useDispatch} from "react-redux";
import {Modal, useModal} from "pulse-modal";
import {
    removeStaffBlockSection,
    setStaffBlockSection
} from "../../../../../../../../store/calendarEntry/actions/CalendarEntryActions";
import FormRow from "../../../../../../../Form/FormRow";
import {DebounceInput} from "react-debounce-input";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../../../Button/MCButton";
import {IconType} from "../../../../../../../Icon/Icon";
import LocalDeleteActionButton from "../../../../../../../Button/LocalDeleteActionButton";
import CalendarEntryGroup from "../Group/CalendarEntryGroup";
import AddCalendarEntryGroup from "../Group/AddCalendarEntryGroup";
import {showErrorToast} from "../../../../../../../../utils/toastUtils";
import FormHeaderWithAction from "../../../../../../../Form/FormHeaderWithAction";

/** Shows the Calendar Entry section with actions */
const CalendarEntrySection = (props: StaffBlockSection) => {
    const settings = useContext(CalendarEntrySettingsContext);
    const dispatch = useDispatch();
    const {isShown, toggle} = useModal();
    const [section, setSection] = useState(props);

    /** Edit staff block section name */
    const editStaffBlockSectionName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSection({
            ...section,
            name: event.target.value
        });
    };

    /** Saves the section. Checks to see if the name is valid before allowing the action to be completed */
    const saveSection = async () => {
        if (section.name.length < 3) {
            showErrorToast("Section name must be more than 3 characters long");
            return;
        }

        await dispatch(setStaffBlockSection(section));

        toggle();
    };

    /** Resets the section details */
    const resetSection = () => {
        setSection(props);
        toggle();
    };

    /** Removes section from calendar entry */
    const deleteSection = async () => {
        await dispatch(removeStaffBlockSection(props.id));
    };
    return (
        <React.Fragment>
            <FormHeaderWithAction
                headerName={props.name}
                icon={IconType.Edit}
                onClick={toggle}
                showIcon={settings.showEditButtons}
                tooltipText={"Edit Section"}
            />
            <div className="entry_settings">
                <div className="row mt-3 mr-0 ml-0">
                    {props.groupings.map((el: StaffAssignmentGrouping) => {
                        return (
                            <React.Fragment key={el.id}>
                                <div className="col-lg-3">
                                    <CalendarEntryGroup {...el} />
                                </div>
                            </React.Fragment>
                        );
                    })}
                    {settings.showEditButtons && (
                        <div className="col-lg-3">
                            <AddCalendarEntryGroup sectionId={props.id} />
                        </div>
                    )}
                </div>
                <Modal
                    isShown={isShown}
                    onClose={resetSection}
                    title={"Edit Section"}
                    modalSize={"sm"}
                    bodyChildren={
                        <React.Fragment>
                            <FormRow
                                rowName={"Edit Section Name"}
                                columnDetailClassName={"pl-0 pr-0"}
                            >
                                <DebounceInput
                                    debounceTimeout={300}
                                    placeholder={"Enter Section Name Here...."}
                                    className="input-fields"
                                    value={section.name}
                                    onChange={editStaffBlockSectionName}
                                />
                            </FormRow>
                        </React.Fragment>
                    }
                    footerChildren={
                        <React.Fragment>
                            <div className="row mt-3 ml-0 mr-0">
                                <MCButton
                                    size={ButtonSize.Large}
                                    innerValue={"Save"}
                                    onClick={saveSection}
                                    colour={ButtonColourOptions.Yellow}
                                    roundedCorner
                                />
                                <MCButton
                                    size={ButtonSize.Large}
                                    innerValue={"Cancel"}
                                    onClick={resetSection}
                                    colour={ButtonColourOptions.DarkBlue}
                                    roundedCorner
                                />
                                <LocalDeleteActionButton
                                    buttonText="Delete Section"
                                    action={deleteSection}
                                    itemName={props.name}
                                />
                            </div>
                        </React.Fragment>
                    }
                />
            </div>
        </React.Fragment>
    );
};

export default CalendarEntrySection;
