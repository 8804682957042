import {routeNames} from "../../../../Navigation/routeNames";
import {AssignmentState, EventsReportEntry} from "../../../../../api/grs";

/** Gets the full path of the Event Report Route */
export function getEventReportPath(calendarName: string, id: number) {
    return `${routeNames.calendar.path}/${encodeURI(calendarName)}${
        routeNames.eventReport.path
    }?id=${id}`;
}

/** Holds information regarding the staff count for the Event report */
export interface EventReportConfirmedStaffCount {
    confirmedCount: number;
    totalAssignmentCount: number;
}

/** Gets the total amount of confirmed staff for an event and the total assignments for that event too */
export function getConfirmedStaffCountForEventReportEntry(
    entry: EventsReportEntry
): EventReportConfirmedStaffCount {
    let confirmed = 0;
    let totalAssignments = 0;

    for (const section of entry.sections) {
        for (const group of section.groups) {
            totalAssignments += group.numSlots;

            for (const assignment of group.assignments) {
                switch (assignment.state) {
                    case AssignmentState.Accepted:
                    case AssignmentState.BriefingAccepted:
                    case AssignmentState.Attended:
                        ++confirmed;
                        break;
                }
            }
        }
    }

    return {
        confirmedCount: confirmed,
        totalAssignmentCount: totalAssignments
    };
}
