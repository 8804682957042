import {Link, useLocation} from "react-router-dom";
import React from "react";
import {RootStore} from "../../../../../../store/Store";
import {useSelector} from "react-redux";
import {NavigationItem} from "../../../../../../store/navigation/actions/NavigationActionTypes";
import {useUserData} from "../../../../../Hooks/useUserData";

export default function HeaderTabs() {
    const location = useLocation();
    const user = useUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const navItems = useSelector((state: RootStore) => state.navigation.data) || [];
    const parentItem = navItems.find((x) => parentTabSelected(x, location.pathname));

    return (
        <div className="top_tabs_wrapper">
            <div className="top_tabs">
                {navItems.map((item) => {
                    if (item.tabsOnly) return null;
                    if (item.superAdminOnly && !user.superAdmin) return null;

                    const itemPath = item.path ?? "";
                    const pathMatch = fuzzyPathMatch(itemPath, location.pathname);
                    const isActive = pathMatch || isInSubArea(location.pathname, item);

                    return HeaderTab(item, isActive);
                })}
            </div>
            {parentItem && <SubTabs item={parentItem} />}
        </div>
    );
}

function HeaderTab(item: NavigationItem, isActive: boolean) {
    let path = item.path;
    if (!path) {
        if (item.children && item.children.length > 0) path = item.children[0].path;
    }

    if (path) {
        return (
            <Link className={`top_tab ${isActive ? "active" : ""}`} key={path} to={path}>
                {item.name}
            </Link>
        );
    } else {
        return (
            <div className={`top_tab ${isActive ? "active" : ""}`} key={item.name}>
                {item.name}
            </div>
        );
    }
}

interface SubTabsProps {
    item: NavigationItem;
}

function SubTabs(props: SubTabsProps) {
    const location = useLocation();
    const user = useUserData();

    return (
        <div className="sub_tabs">
            {props.item.children?.map((child) => {
                if (child.superAdminOnly && !user.superAdmin) return null;

                const isActive = fuzzyPathMatch(child.path, location.pathname);
                return (
                    <Link
                        className={`sub_tab ${isActive ? "active" : ""}`}
                        key={child.name}
                        to={child.path ?? ""}
                    >
                        {child.name}
                    </Link>
                );
            })}
        </div>
    );
}

function getPathComponentOnly(path: string): string {
    const questPos = path.indexOf("?");
    if (questPos <= 0) return path;

    return path.substring(0, questPos);
}

function fuzzyPathMatch(
    possiblePath: string | null | undefined,
    actualPath: string | null | undefined
): boolean {
    if (!possiblePath || !actualPath) return false;

    possiblePath = getPathComponentOnly(possiblePath.replaceAll("%20", " "));
    actualPath = getPathComponentOnly(actualPath.replaceAll("%20", " "));

    return possiblePath === actualPath;
}

function parentTabSelected(item: NavigationItem, path: string): boolean {
    // No children, can't be selected!
    if (!item.children || item.children.length === 0) return false;

    // Direct match
    if (fuzzyPathMatch(item.path, path)) return true;

    // Child match
    for (const child of item.children) {
        if (fuzzyPathMatch(child.path, path)) return true;
    }

    return false;
}

function isInSubArea(path: string, item: NavigationItem): boolean {
    if (!item.children || item.children.length === 0) return false;

    for (const child of item.children) {
        if (fuzzyPathMatch(child.path, path)) return true;
    }

    return false;
}
