import React, {useContext, useEffect, useState} from "react";
import {StaffAssignment, StaffAssignmentGrouping} from "../../../../../../../../api/grs";
import {CalendarEntrySettingsContext} from "../../../../../../../Context/CalendarEntrySettingsContext";
import {useDispatch} from "react-redux";
import {Modal, useModal} from "pulse-modal";
import {getClinicalGradeToggleOptionsFromClinicalGrades} from "../../../../../../GlobalStaffBlock/AddEdit/Helpers/staffBlockHelpers";
import {GenericNameValueToggle} from "../../../../../../../../utils/sortingUtils";
import {
    convertUserDataListToStaffLinkWithEmailList,
    filterStaffLinkAddedDetailsByClinicalGrade,
    getAvailableStaff,
    StaffLinkAddedDetails
} from "../../../../../Helpers/calenderEntryHelpers";
import Tooltip from "../../../../../../../Tooltip/Tooltip";
import Icon, {IconType} from "../../../../../../../Icon/Icon";
import FormRow from "../../../../../../../Form/FormRow";
import {DebounceInput} from "react-debounce-input";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../../../Button/MCButton";
import {
    removeStaffAssignmentGrouping,
    setStaffAssignmentGrouping
} from "../../../../../../../../store/calendarEntry/actions/CalendarEntryActions";
import {updateStaffGroupingClinicalGrades} from "../../../../../../CalendarForm/Helpers/calendarFormHelpers";
import LocalDeleteActionButton from "../../../../../../../Button/LocalDeleteActionButton";
import {showErrorToast} from "../../../../../../../../utils/toastUtils";
import {AvailableStaffLinkListContext} from "../../../../../../../Context/AvailableStaffLinkListContext";
import {StaffLinkListContext} from "../../../../../../../Context/StaffLinkListContext";
import AddCalendarEntryStaffAssignment from "../Assignment/AddCalendarEntryStaffAssignment";
import CalendarEntryStaffAssignment from "../Assignment/CalendarEntryStaffAssignment";
import {StaffListContext} from "../../../../../../../Context/StaffListContext";
import {CalendarEntryContext} from "../../../../../../../Context/CalendarEntryContext";

/** The grouping for the Calendar Entry */
const CalendarEntryGroup = (props: StaffAssignmentGrouping) => {
    const settings = useContext(CalendarEntrySettingsContext);
    const staffList = useContext(StaffListContext);
    const entry = useContext(CalendarEntryContext);
    const dispatch = useDispatch();
    const {isShown, toggle} = useModal();
    const [group, setGroup] = useState(props);

    const [clinicalGrades, setClinicalGrades] = useState<GenericNameValueToggle[]>(
        getClinicalGradeToggleOptionsFromClinicalGrades(props.clinicalGrades)
    );
    const [filteredStaff, setFilteredStaff] = useState<StaffLinkAddedDetails[]>([]);

    /** Edit Group Name */
    const editGroupName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGroup({
            ...group,
            name: event.target.value
        });
    };

    /** Updates the toggled option of the clinical grade for the group */
    const changeToggledOption = (value: GenericNameValueToggle) => {
        const newDetails = updateStaffGroupingClinicalGrades(group, value);
        setGroup(newDetails);
    };

    /** Anytime the group is altered, we update the clinical grades. */
    useEffect(() => {
        const toggleFieldProps = getClinicalGradeToggleOptionsFromClinicalGrades(
            group.clinicalGrades
        );
        setClinicalGrades(toggleFieldProps);
    }, [group]);

    /** Reset Group */
    const resetGroupDetails = () => {
        setGroup(props);
        setClinicalGrades(getClinicalGradeToggleOptionsFromClinicalGrades(props.clinicalGrades));
        toggle();
    };

    /** Saves the group locally */
    const saveGroup = async () => {
        if (group.name.length < 3) {
            showErrorToast("Group must be more than 3 characters");
            return;
        }

        await dispatch(setStaffAssignmentGrouping(group));

        toggle();
    };

    /** Removes group from section*/
    const deleteGroup = async () => {
        await dispatch(removeStaffAssignmentGrouping(props.id));
    };

    /** We check on props, not the local group as we only want to apply filter when props are changed (Will only change once) */
    useEffect(() => {
        if (!entry) return;
        const availableStaff = getAvailableStaff(entry, staffList);
        setFilteredStaff(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            filterStaffLinkAddedDetailsByClinicalGrade(props.clinicalGrades, availableStaff)
        );
    }, [props.clinicalGrades, entry]);

    return (
        <React.Fragment>
            <div className="row ml-0 mr-0">
                <h6 className="group_titles">
                    {props.name}
                    {settings.showEditButtons && (
                        <React.Fragment>
                            <Tooltip
                                wrapperClassName={"ml-3"}
                                tooltipText={"Edit Group"}
                                place={"right"}
                                size={"md"}
                            >
                                <Icon
                                    className={"cursor-pointer"}
                                    rootElement={"Span"}
                                    icon={IconType.Edit}
                                    onClick={toggle}
                                    size={"Medium"}
                                />
                            </Tooltip>
                        </React.Fragment>
                    )}
                </h6>
            </div>
            {/** Get all staff members and provide a context for child components */}
            <StaffLinkListContext.Provider
                value={convertUserDataListToStaffLinkWithEmailList(staffList)}
            >
                <AvailableStaffLinkListContext.Provider value={filteredStaff}>
                    <div className="row">
                        <div className="col group_list">
                            {props.assignments.map((el: StaffAssignment) => {
                                return <CalendarEntryStaffAssignment {...el} key={el.id} />;
                            })}
                            {settings.showEditButtons && (
                                <AddCalendarEntryStaffAssignment groupId={props.id} />
                            )}
                        </div>
                    </div>
                </AvailableStaffLinkListContext.Provider>
            </StaffLinkListContext.Provider>
            <Modal
                isShown={isShown}
                onClose={resetGroupDetails}
                title={"Edit Group"}
                modalSize={"sm"}
                bodyChildren={
                    <React.Fragment>
                        <FormRow rowName={"Edit Group Name"} columnDetailClassName={"pl-0 pr-0"}>
                            <DebounceInput
                                debounceTimeout={300}
                                placeholder={"Enter new group name here..."}
                                className="input-fields"
                                value={group.name}
                                onChange={editGroupName}
                            />
                        </FormRow>
                        <FormRow
                            rowName={"Group Clinical Grades"}
                            columnDetailClassName={"pl-1 pr-1"}
                        >
                            {clinicalGrades.map((el: GenericNameValueToggle) => {
                                return (
                                    <div className="row ml-0 mr-0" key={el.value}>
                                        <label className={"cursor-pointer"}>
                                            <input
                                                type="checkbox"
                                                name={el.name}
                                                value={el.value}
                                                onChange={() => changeToggledOption(el)}
                                                checked={el.checked}
                                            />
                                            <span className="ml-2">{el.name} </span>
                                        </label>
                                    </div>
                                );
                            })}
                        </FormRow>
                    </React.Fragment>
                }
                footerChildren={
                    <React.Fragment>
                        <div className="row ml-0 mr-0">
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"Save"}
                                onClick={saveGroup}
                                colour={ButtonColourOptions.Yellow}
                                roundedCorner
                            />
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"Cancel"}
                                onClick={resetGroupDetails}
                                colour={ButtonColourOptions.DarkBlue}
                                roundedCorner
                            />
                            <LocalDeleteActionButton
                                buttonText="Delete Group"
                                action={deleteGroup}
                                itemName={props.name}
                            />
                        </div>
                    </React.Fragment>
                }
            />
        </React.Fragment>
    );
};

export default CalendarEntryGroup;
