import React from "react";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import {Modal, useModal} from "pulse-modal";
import Icon, {IconType} from "../../../Icon/Icon";
import {useDisconnectConfirmation} from "./Hooks/useDisconnectConfirmation";
import {useUserData} from "../../../Hooks/useUserData";

function DisconnectConfirmation({message}: Props) {
    const user = useUserData();
    const {isShown, toggle} = useModal();
    const {onDisconnect} = useDisconnectConfirmation();
    const showDisconnect = user.superAdmin === true;

    return (
        <React.Fragment>
            {showDisconnect && (
                <button className="xero-disconnect-button" onClick={toggle}>
                    <Icon
                        rootElement={"Span"}
                        className="mr-3"
                        icon={IconType.Logout}
                        size={"Small"}
                    />
                    Disconnect
                </button>
            )}
            <Modal
                isShown={isShown}
                onClose={toggle}
                title={"Disconnect"}
                modalSize={"sm"}
                bodyChildren={
                    <React.Fragment>
                        <div className="row ml-0 mr-0">
                            <p>{message}</p>
                        </div>
                    </React.Fragment>
                }
                footerChildren={
                    <React.Fragment>
                        <div className="row mt-3 ml-0 mr-0">
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"Yes"}
                                onClick={async () => {
                                    await onDisconnect();
                                    toggle();
                                }}
                                colour={ButtonColourOptions.Yellow}
                                roundedCorner
                            />
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"No"}
                                onClick={toggle}
                                colour={ButtonColourOptions.DarkBlue}
                                roundedCorner
                            />
                        </div>
                    </React.Fragment>
                }
            />
        </React.Fragment>
    );
}

export default DisconnectConfirmation;

interface Props {
    message: string;
}
