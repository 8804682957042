import {NavigationItem} from "../../../../store/navigation/actions/NavigationActionTypes";
import {getSystemAdminGroupedRoutes} from "../../GroupRosteringSystem/GroupRosteringSystem";
import {routeNames} from "../../../Navigation/routeNames";

export function useXeroRoutes() {
    function getSystemAdminRoutes(): NavigationItem[] {
        return [
            ...getSystemAdminGroupedRoutes(),
            {
                name: routeNames.xeroLogin.name,
                path: routeNames.xeroLogin.path
            }
        ];
    }

    function getCallbackRoutes(): NavigationItem[] {
        return [
            ...getSystemAdminGroupedRoutes(),
            {
                name: routeNames.xeroCallback.name,
                path: routeNames.xeroCallback.path
            }
        ];
    }

    function getInvoiceListingRoutes(): NavigationItem[] {
        return [
            ...getSystemAdminGroupedRoutes(),
            {
                name: routeNames.xeroInvoices.name,
                path: routeNames.xeroInvoices.path
            }
        ];
    }

    return {
        getSystemAdminRoutes,
        getCallbackRoutes,
        getInvoiceListingRoutes
    };
}
