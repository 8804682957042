import React, {useContext, useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import moment, {Moment} from "moment-timezone";
import {CalendarContext} from "../../../Context/CalendarContext";
import {CalendarEntryListRequest, CalendarTypeEnum} from "../../../../api/grs";
import {useHistory} from "react-router-dom";
import {useQuery} from "../../../Hooks/useQuery";
import {isSameISOWeek} from "date-fns";
import {nullifyCalendarEntriesListStore} from "../../../../store/calendarEntryList/actions/CalendarEntryListActions";
import {nullifyCalendarEntriesListForCalendarPage} from "../../../../store/fullCalendarItemList/actions/FullCalendarItemListActions";
import {useDispatch} from "react-redux";
import {formatUnixToDDMMYYYY} from "../../../../utils/momentUtils";
import {RequestFilterProps} from "../../../Filters/helpers/filterHelpers";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import DatePickerInputButton from "../../../Button/DatePickerInputButton";
import useMcConfig from "../../../Hooks/useMcConfig";

const CalendarFilters = (props: RequestFilterProps<CalendarEntryListRequest>) => {
    const calendar = useContext(CalendarContext);
    const [startDate, setStartDate] = useState<Moment>();
    const history = useHistory();
    const query = useQuery();
    const dispatch = useDispatch();
    const {config} = useMcConfig();

    /** Upon mounting, see if we can get query string exists, if so, set date from this. */
    useEffect(() => {
        const month = query.get("month");
        const week = query.get("week");
        if (week) {
            setStartDate(moment.unix(+week));
            return;
        }
        if (month) {
            setStartDate(moment.unix(+month));
            return;
        }
        setStartDate(moment());

        return function () {
            dispatch(nullifyCalendarEntriesListStore());
            dispatch(nullifyCalendarEntriesListForCalendarPage());
        };
    }, []);

    /** Selected when the date changes */
    const onDateChanged = (date: Date | null) => {
        if (!date) return;

        setStartDate(moment(date));
    };

    /** Watches for changes to start date */
    useEffect(() => {
        /** Builds request based on the calendar, if there is no calendar, we are looking at a staff calendar */
        const buildCalendarEntryRequest = (start: Moment): CalendarEntryListRequest | undefined => {
            const startOfMonth = start.clone().startOf("month");
            const endOfMonth = start.clone().endOf("month");
            const startOfIsoWeek = start.clone().startOf("isoWeek");
            const endOfIsoWeek = start.clone().endOf("isoWeek");
            if (!calendar) {
                return {
                    startDate: startOfMonth.clone().subtract(8, "days").unix(),
                    endDate: endOfMonth.clone().add(8, "days").unix()
                };
            }
            switch (calendar.calendarType) {
                case CalendarTypeEnum.Event:
                    history.push({
                        search: `?id=${calendar.id}&month=${startOfMonth.unix()}`
                    });
                    return {
                        startDate: startOfMonth.clone().subtract(8, "days").unix(),
                        endDate: endOfMonth.clone().add(8, "days").unix(),
                        calendarId: calendar.id
                    };
                case CalendarTypeEnum.Frontline:
                    history.push({
                        search: `?id=${calendar.id}&week=${startOfIsoWeek.unix()}`
                    });
                    return {
                        startDate: startOfIsoWeek.unix(),
                        endDate: endOfIsoWeek.unix(),
                        calendarId: calendar.id
                    };
            }
        };

        if (!startDate) return;
        const request = buildCalendarEntryRequest(startDate);
        if (!request) return;

        props.onRequestChanged(request);
    }, [startDate]);

    /** Gets the date picker format for the iso week */
    const getIsoWeekPickerDateFormat = (): string | string[] | undefined => {
        if (!startDate) return [];
        const startOfIsoWeek = startDate.clone().startOf("isoWeek");
        const endOfIsoWeek = startDate.clone().endOf("isoWeek");

        return `${startOfIsoWeek.tz(config.timezone).format("DD/MM/YYYY")} - ${endOfIsoWeek
            .tz(config.timezone)
            .format("DD/MM/YYYY")}`;
    };

    /** Gets the iso week class name */
    const getIsoWeekDayClassName = (dateLeft: Date, dateRight: Date) => {
        return isSameISOWeek(dateLeft, dateRight) ? "react-datepicker__day--selected" : "";
    };

    const previousWeekButtonText = () => {
        if (!startDate) return "";
        const unixTime = startDate.clone().subtract(1, "week").startOf("isoWeek").unix();
        return `Previous (${formatUnixToDDMMYYYY(unixTime)})`;
    };

    const nextWeekButtonText = () => {
        if (!startDate) return "";
        const unixTime = startDate.clone().add(1, "week").startOf("isoWeek").unix();
        return `Next (${formatUnixToDDMMYYYY(unixTime)})`;
    };

    const goToPreviousWeek = () => {
        if (!startDate) return;
        const previousWeek = startDate.clone().subtract(1, "week");
        setStartDate(previousWeek);
    };

    const goToNextWeek = () => {
        if (!startDate) return;
        const nextWeek = startDate.clone().add(1, "week");
        setStartDate(nextWeek);
    };

    return (
        <React.Fragment>
            <table className="filters-table mt-3">
                <tbody className="filters-lg">
                    <tr>
                        <th className="filter-heading w-50 pl-3 pr-3">Select Month</th>
                    </tr>
                    <tr>
                        <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                            {startDate && calendar && (
                                <React.Fragment>
                                    {calendar.calendarType === CalendarTypeEnum.Event ? (
                                        <DatePicker
                                            selected={startDate.toDate()}
                                            onChange={onDateChanged}
                                            showMonthYearPicker
                                            dateFormat="MMMM yyyy"
                                            portalId="root-portal"
                                            customInput={<DatePickerInputButton />}
                                        />
                                    ) : (
                                        <DatePicker
                                            selected={startDate.toDate()}
                                            onChange={onDateChanged}
                                            dateFormat={getIsoWeekPickerDateFormat()}
                                            dayClassName={(date: Date) =>
                                                getIsoWeekDayClassName(date, startDate.toDate())
                                            }
                                            calendarStartDay={1}
                                            portalId="root-portal"
                                            customInput={<DatePickerInputButton />}
                                        />
                                    )}
                                </React.Fragment>
                            )}
                        </td>
                    </tr>
                </tbody>
                <tbody className="filters-md">
                    <tr>
                        <th className="filter-heading w-50 pl-3 pr-3">Select Month</th>
                    </tr>
                    <tr>
                        <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                            {startDate && calendar && (
                                <React.Fragment>
                                    {calendar.calendarType === CalendarTypeEnum.Event ? (
                                        <DatePicker
                                            selected={startDate.toDate()}
                                            onChange={onDateChanged}
                                            showMonthYearPicker
                                            dateFormat="MMMM yyyy"
                                            portalId="root-portal"
                                            customInput={<DatePickerInputButton />}
                                        />
                                    ) : (
                                        <DatePicker
                                            selected={startDate.toDate()}
                                            onChange={onDateChanged}
                                            dateFormat={getIsoWeekPickerDateFormat()}
                                            dayClassName={(date: Date) =>
                                                getIsoWeekDayClassName(date, startDate.toDate())
                                            }
                                            calendarStartDay={1}
                                            portalId="root-portal"
                                            customInput={<DatePickerInputButton />}
                                        />
                                    )}
                                </React.Fragment>
                            )}
                        </td>
                    </tr>
                </tbody>
                <tbody className="filters-sm">
                    <tr>
                        <th className="filter-heading w-100 pl-3 pr-3">Month</th>
                    </tr>
                    <tr>
                        <td className="filter-heading w-100 pl-3 pr-3 pb-2">
                            {startDate && calendar && (
                                <React.Fragment>
                                    {calendar.calendarType === CalendarTypeEnum.Event ? (
                                        <DatePicker
                                            selected={startDate.toDate()}
                                            onChange={onDateChanged}
                                            showMonthYearPicker
                                            dateFormat="MMMM yyyy"
                                            portalId="root-portal"
                                            customInput={<DatePickerInputButton />}
                                        />
                                    ) : (
                                        <DatePicker
                                            selected={startDate.toDate()}
                                            onChange={onDateChanged}
                                            dateFormat={getIsoWeekPickerDateFormat()}
                                            dayClassName={(date: Date) =>
                                                getIsoWeekDayClassName(date, startDate.toDate())
                                            }
                                            calendarStartDay={1}
                                            portalId="root-portal"
                                            customInput={<DatePickerInputButton />}
                                        />
                                    )}
                                </React.Fragment>
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
            {calendar && calendar.calendarType === CalendarTypeEnum.Frontline && (
                <table className="filters-table">
                    <tbody className="filters-lg">
                        <tr>
                            <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                                <MCButton
                                    size={ButtonSize.Large}
                                    innerValue={previousWeekButtonText()}
                                    onClick={goToPreviousWeek}
                                    colour={ButtonColourOptions.DarkBlue}
                                    roundedCorner
                                />
                            </td>
                            <td align={"right"} className="filter-heading w-50 pl-3 pr-3 pb-2">
                                <MCButton
                                    size={ButtonSize.Large}
                                    innerValue={nextWeekButtonText()}
                                    onClick={goToNextWeek}
                                    colour={ButtonColourOptions.DarkBlue}
                                    roundedCorner
                                />
                            </td>
                        </tr>
                    </tbody>
                    <tbody className="filters-md">
                        <tr>
                            <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                                <MCButton
                                    size={ButtonSize.Large}
                                    innerValue={previousWeekButtonText()}
                                    onClick={goToPreviousWeek}
                                    colour={ButtonColourOptions.DarkBlue}
                                    roundedCorner
                                />
                            </td>
                            <td align={"right"} className="filter-heading w-50 pl-3 pr-3 pb-2">
                                <MCButton
                                    size={ButtonSize.Large}
                                    innerValue={nextWeekButtonText()}
                                    onClick={goToNextWeek}
                                    colour={ButtonColourOptions.DarkBlue}
                                    roundedCorner
                                />
                            </td>
                        </tr>
                    </tbody>
                    <tbody className="filters-sm">
                        <tr>
                            <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                                <MCButton
                                    size={ButtonSize.Large}
                                    innerValue={previousWeekButtonText()}
                                    onClick={goToPreviousWeek}
                                    colour={ButtonColourOptions.DarkBlue}
                                    roundedCorner
                                />
                            </td>
                            <td align={"right"} className="filter-heading w-50 pl-3 pr-3 pb-2">
                                <MCButton
                                    size={ButtonSize.Large}
                                    innerValue={nextWeekButtonText()}
                                    onClick={goToNextWeek}
                                    colour={ButtonColourOptions.DarkBlue}
                                    roundedCorner
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            )}
        </React.Fragment>
    );
};

export default CalendarFilters;
