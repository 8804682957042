import React from "react";
import {useXero} from "./Hooks/useXero";
import XeroLoginPortal from "./Components/XeroLoginPortal";
import OverlayLoading from "../../../Loading/OverlayLoading";

function XeroLogin() {
    const {state} = useXero();
    return (
        <React.Fragment>
            <div className="page-container">
                {state.loading && <OverlayLoading />}

                {state.data.startData && (
                    <XeroLoginPortal loginUrl={state.data.startData?.loginUrl} />
                )}
            </div>
        </React.Fragment>
    );
}

export default XeroLogin;
