import React, {useContext} from "react";
import {CalendarEntry} from "../../../../../../api/grs";
import FormHeader from "../../../../../Form/FormHeader";
import CalendarEntryDescription from "./CalendarEntryDescription";
import CalendarEntryVenue from "./CalendarEntryVenue";
import CalendarEntryBillingDropdown from "./CalendarEntryBillingDropdown";
import {ShowAdvancedControlsContext} from "../../../../../../contexts/advancedControls";

export default function CalendarEntryGeneralDetails(props: CalendarEntry) {
    const showAdvancedContext = useContext(ShowAdvancedControlsContext);

    return (
        <React.Fragment>
            <FormHeader headerName={"General Details"} />
            <CalendarEntryDescription {...props} />
            <CalendarEntryVenue {...props} />
            {showAdvancedContext?.show && <CalendarEntryBillingDropdown {...props} />}
        </React.Fragment>
    );
}
