import React, {MutableRefObject, useEffect, useLayoutEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../store/Store";
import {getCalendarCompliance} from "../../../../store/calendarCompliance/actions/CalendarComplianceActions";
import {CalendarCompliance, CalendarGroup} from "../../../../api/grs";
import {getLatestCompliance} from "../Logic/compliance";
import {getStaffInterestForDates} from "../../../../store/staffInterestQuery/actions/StaffInterestQueryResponseActions";
import moment from "moment";
import StaffInterestListWidget from "./StaffInterestListWidget";
import {getAllMedicareStaffMembers} from "../../../../store/staffList/actions/StaffListActions";
import {fetchAllVenues} from "../../../../store/venueList/actions/VenueListActions";
import {getCalendarGroupList} from "../../../../store/calendarGroupList/actions/CalendarGroupListActions";
import GroupComplianceWidget, {ComplianceBox} from "./GroupComplianceWidget";

function updateInterestHeight(
    entries: ResizeObserverEntry[],
    ref: MutableRefObject<HTMLDivElement | null>,
    setHeight: (height: number) => void
) {
    const leftColRef = ref.current;
    if (!leftColRef) return;
    if (entries.length == 0) return;

    const height = (entries[0].target as HTMLDivElement).offsetHeight;
    if (height > 100) setHeight(height + 16);
    else setHeight(4000);
}

export default function GrsDashContainer() {
    const dispatch = useDispatch();
    const [interestHeight, setInterestHeight] = useState(4000);
    const leftColRef = useRef<HTMLDivElement | null>(null);
    const compliance = useSelector((state: RootStore) => state.calendarCompliance);
    const staffInterest = useSelector((state: RootStore) => state.staffInterestQueryResponse);
    const calendarGroups = useSelector((state: RootStore) => state.calendarGroupList.data);
    const complianceBoxes: ComplianceBox[] = getComplianceBoxes(
        compliance?.data?.compliance,
        calendarGroups
    );

    useLayoutEffect(() => {
        if (!leftColRef.current) return;

        const currentHeight = leftColRef.current.offsetHeight;
        if (currentHeight > 100) setInterestHeight(currentHeight + 16);

        const newObserver = new ResizeObserver((entries) =>
            updateInterestHeight(entries, leftColRef, (height) => setInterestHeight(height))
        );
        newObserver.observe(leftColRef.current);

        return () => {
            newObserver.disconnect();
        };
    }, [leftColRef, setInterestHeight]);

    useEffect(() => {
        const minDate = moment().startOf("day");
        const maxDate = moment().startOf("day").add(1, "month");

        dispatch(getAllMedicareStaffMembers()); // Bad
        dispatch(fetchAllVenues()); // Bad
        dispatch(getCalendarCompliance());
        dispatch(getCalendarGroupList()); // Not ideal
        dispatch(
            getStaffInterestForDates({
                startDate: minDate.unix(),
                endDate: maxDate.unix(),
                includeFullEntries: false
            })
        );
    }, [dispatch]);

    return (
        <div className="dash-container">
            <div className="dash-row-outer">
                <div className="dash-left-container">
                    <div className="dash-row-compliance-stats" ref={leftColRef}>
                        {complianceBoxes.map((box) => (
                            <GroupComplianceWidget key={box.name} box={box} />
                        ))}
                    </div>
                </div>
                <div
                    className="dash-right-container"
                    style={{"--max-height": `${interestHeight}px`} as React.CSSProperties}
                >
                    <StaffInterestListWidget data={staffInterest.data} />
                </div>
            </div>
        </div>
    );
}

function getComplianceBoxes(
    complianceRecords: CalendarCompliance[] | null | undefined,
    groups: CalendarGroup[] | null | undefined
): ComplianceBox[] {
    // No data
    if (!complianceRecords || complianceRecords.length === 0 || !groups || groups.length === 0)
        return [];

    const latestCompliance = getLatestCompliance(complianceRecords);
    const boxMap = new Map<string, ComplianceBox>();

    for (const record of latestCompliance) {
        const group = groups.find((g) => g.calendarIds.indexOf(record.id) !== -1);
        const groupName = group?.name || "Other";

        let boxRecord = boxMap.get(groupName);
        if (!boxRecord) {
            boxRecord = {
                name: groupName,
                group,
                data: []
            };
            boxMap.set(groupName, boxRecord);
        }

        boxRecord.data.push(record);
    }

    const output = Array.from(boxMap.values());
    output.sort((a, b) => a.name.localeCompare(b.name));

    const otherIndex = output.findIndex((box) => box.name === "Other");
    if (otherIndex !== -1) {
        const other = output.splice(otherIndex, 1)[0];
        output.push(other);
    }

    return output;
}
