import React, {useEffect, useState} from "react";
import {AvailableShiftCalendarEntry, StaffInterestQueryFields} from "../../../../../api/grs";
import FullCalendar, {EventClickArg} from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import {toArray} from "../../../../../utils/sortingUtils";
import {renderEventContent} from "../../../AdminCalendar/Helpers/calendarUtils";
import moment from "moment-timezone";
import {useQuery} from "../../../../Hooks/useQuery";
import {CalendarEventItem} from "../../../../../store/fullCalendarItemList/actions/FullCalendarItemListActionTypes";
import {sortEventsForAvailableShiftsCalendar} from "../Helpers/availableShiftsListCalendar";
import {useUserData} from "../../../../Hooks/useUserData";
import {Modal, useModal} from "pulse-modal";
import AvailableShiftsEntryForm from "./AvailableShiftsEntryForm";
import {useDispatch} from "react-redux";
import {
    getStaffInterestForDates,
    nullifyStaffInterestQueryResponseStore
} from "../../../../../store/staffInterestQuery/actions/StaffInterestQueryResponseActions";

const AvailableShiftsCalendarView = (props: AvailableShiftCalendarEntry[]) => {
    const dispatch = useDispatch();
    const [events, setEvents] = useState<CalendarEventItem[]>([]);
    //Full Calendar
    const calendarRef = React.createRef<any>();
    const [epochDate, setEpochDate] = useState<number>(moment().unix());
    const query = useQuery();
    const {isShown, toggle} = useModal();
    const [selectedEntry, setSelectedEntry] = useState<AvailableShiftCalendarEntry>();
    const user = useUserData();
    /** Anytime the search parameters change, update the date */
    useEffect(() => {
        updateEpochTime();
    }, [window.location.search]);

    useEffect(() => {
        return function () {
            dispatch(nullifyStaffInterestQueryResponseStore());
        };
    }, []);

    /** Updates epoch time for calendar API */
    const updateEpochTime = () => {
        const date = query.get("month");
        if (!date) return;
        setEpochDate(+date);
    };

    /** Anytime the date changes, update calendar api */
    useEffect(() => {
        if (!calendarRef.current) return;
        const calendarApi = calendarRef.current.getApi();
        //FullCalendarApi works in ms, not s
        calendarApi.gotoDate(+epochDate * 1000);
    }, [epochDate]);

    useEffect(() => {
        if (user.username.length === 0) return;
        setEvents(sortEventsForAvailableShiftsCalendar(toArray(props), user));
    }, [props, user]);

    const openEntry = async (args: EventClickArg) => {
        const entries = toArray(props);
        if (entries.length === 0) return;
        const id = +args.event.id;
        const entry = entries.find((el: AvailableShiftCalendarEntry) => el.id === id);
        if (!entry) return;
        const endOfDay = moment.unix(entry.startDate).endOf("day").unix();
        const startOfCurrentDay = moment().startOf("day").unix();

        //We don't want to allow for the user to register interest in historic events
        if (startOfCurrentDay > endOfDay) {
            return;
        }

        await getAvailableShiftListForDay(entry);

        setSelectedEntry(entry);
        toggle();
    };

    async function getAvailableShiftListForDay(entry: AvailableShiftCalendarEntry) {
        const {startDate} = entry;
        const request: StaffInterestQueryFields = {
            startDate: moment.unix(startDate).clone().startOf("day").unix(),
            endDate: moment.unix(startDate).clone().endOf("day").unix(),
            includeFullEntries: true
        };

        await dispatch(getStaffInterestForDates(request));
    }

    const onModalClose = () => {
        setSelectedEntry(undefined);
        toggle();
        dispatch(nullifyStaffInterestQueryResponseStore());
    };
    return (
        <React.Fragment>
            <div className="calendar-wrapper mt-4 mb-5">
                <div className="full-calendar-wrapper">
                    <FullCalendar
                        ref={calendarRef}
                        plugins={[dayGridPlugin, interactionPlugin]}
                        headerToolbar={{left: "", center: "", right: ""}}
                        initialView="dayGridMonth"
                        selectable={true}
                        firstDay={1}
                        eventOrder="color"
                        longPressDelay={500}
                        events={events}
                        initialDate={epochDate * 1000}
                        eventContent={renderEventContent}
                        eventClick={openEntry}
                        height="auto"
                    />
                </div>
            </div>
            <Modal
                modalSize={"lg"}
                title={"Event Details"}
                bodyChildren={
                    <React.Fragment>
                        {selectedEntry && (
                            <AvailableShiftsEntryForm
                                entry={selectedEntry}
                                callback={onModalClose}
                            />
                        )}
                    </React.Fragment>
                }
                onClose={toggle}
                isShown={isShown}
            />
        </React.Fragment>
    );
};

export default AvailableShiftsCalendarView;
