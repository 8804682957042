import {ComponentChildrenProps} from "../../utils/componentUtils";
import React from "react";
import Tooltip from "../Tooltip/Tooltip";
import Icon, {IconSize, IconType} from "../Icon/Icon";

const FormRowWithTooltip = ({
    children,
    rowName,
    icon,
    iconSize,
    iconClassName,
    tooltipText
}: FormRowProps) => {
    return (
        <div className="mc-form-row">
            <div className="row">
                <div className="col">
                    <h6 className="mb-0">
                        {rowName}
                        <Tooltip
                            tooltipText={tooltipText}
                            wrapperClassName={"ml-2 cursor-pointer"}
                            size={"lg"}
                            place={"right"}
                            theme={"dark"}
                        >
                            <Icon
                                rootElement={"Span"}
                                icon={icon}
                                size={iconSize || "Large"}
                                className={iconClassName || ""}
                            />
                        </Tooltip>
                    </h6>
                </div>
            </div>
            <div className="row">
                <div className="col">{children}</div>
            </div>
        </div>
    );
};

export default FormRowWithTooltip;

interface FormRowProps extends ComponentChildrenProps {
    rowName: string;
    rowClassName?: string;
    columnHeaderClassName?: string;
    columnDetailClassName?: string;
    formRowClassName?: string;
    icon: IconType;
    iconSize?: IconSize;
    iconClassName?: string;
    tooltipText: string;
}
