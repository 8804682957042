import React, {ReactNode} from "react";
import {getConfirmedStaffCountForEventReportEntry} from "../Helpers/eventReportHelpers";
import {
    formatUnixToMMMMYYYY,
    formatUnixToQYYYY,
    formatUnixToYYYY
} from "../../../../../utils/momentUtils";
import {IoCalendarNumberOutline, IoCalendarOutline} from "react-icons/io5";
import {MdCalendarMonth} from "react-icons/md";
import {useSelector} from "react-redux";
import {RootStore} from "../../../../../store/Store";
import {EventsReportResponse} from "../../../../../api/grs";
import moment from "moment";
import {getPercentage} from "../../../../../utils/mathUtils";

export default function EventReportStats() {
    const eventReportStore = useSelector((state: RootStore) => state.eventReport);
    if (!eventReportStore.data) return null;
    const stats = getEventReportStats(eventReportStore.data);

    return (
        <div className="dash-widget">
            <h2>Overall fulfillment</h2>

            <div className="calendar-entry-stats">
                <EventReportStatDisplay
                    stat={stats.monthly}
                    icon={<IoCalendarNumberOutline />}
                    className="monthly"
                />
                <EventReportStatDisplay
                    stat={stats.quarterly}
                    icon={<MdCalendarMonth />}
                    className="quarterly"
                />
                <EventReportStatDisplay
                    stat={stats.yearly}
                    icon={<IoCalendarOutline />}
                    className="yearly"
                />
            </div>
        </div>
    );
}

interface EventReportStatDisplayProps {
    stat: EventReportStat;
    icon: ReactNode;
    className: string;
}

function EventReportStatDisplay(props: EventReportStatDisplayProps) {
    const percentage = getPercentage(props.stat.totalConfirmed, props.stat.totalAssignments);
    const valueStr = `${percentage}%`;

    return (
        <div className={`entry-stat ${props.className}`}>
            <div className="entry-stat-label">
                <div className="entry-stat-icon">{props.icon}</div>
                <div className="entry-stat-value">{valueStr}</div>
            </div>
            <div className="entry-stat-details">
                <div className="entry-stat-details-name">{props.stat.name}</div>
                <div className="entry-stat-details-value"></div>
            </div>
        </div>
    );
}

interface EventReportStat {
    name: string;
    totalAssignments: number;
    totalConfirmed: number;
}

interface EventReportStats {
    monthly: EventReportStat;
    quarterly: EventReportStat;
    yearly: EventReportStat;
}

function getEventReportStats(response: EventsReportResponse): EventReportStats {
    const dateRanges = getEventReportDates();
    const monthStat = {
        name: `Events Staffed - ${formatUnixToMMMMYYYY(dateRanges.monthStart)}`,
        totalAssignments: 0,
        totalConfirmed: 0
    };
    const quarterStat = {
        name: `Events Staffed - ${formatUnixToQYYYY(dateRanges.quarterStart)}`,
        totalAssignments: 0,
        totalConfirmed: 0
    };
    const yearStat = {
        name: `Events Staffed - ${formatUnixToYYYY(dateRanges.yearStart)}`,
        totalAssignments: 0,
        totalConfirmed: 0
    };

    // No data, return empty
    if (!response.entries || response.entries.length === 0) {
        return {
            monthly: monthStat,
            quarterly: quarterStat,
            yearly: yearStat
        };
    }

    // Calculate the counts
    for (const entry of response.entries) {
        const {totalAssignmentCount, confirmedCount} =
            getConfirmedStaffCountForEventReportEntry(entry);

        if (entry.startDate >= dateRanges.monthStart && entry.startDate < dateRanges.monthEnd) {
            monthStat.totalAssignments += totalAssignmentCount;
            monthStat.totalConfirmed += confirmedCount;
        }
        if (entry.startDate >= dateRanges.quarterStart && entry.startDate < dateRanges.quarterEnd) {
            quarterStat.totalAssignments += totalAssignmentCount;
            quarterStat.totalConfirmed += confirmedCount;
        }
        if (entry.startDate >= dateRanges.yearStart && entry.startDate < dateRanges.yearEnd) {
            yearStat.totalAssignments += totalAssignmentCount;
            yearStat.totalConfirmed += confirmedCount;
        }
    }

    return {
        monthly: monthStat,
        quarterly: quarterStat,
        yearly: yearStat
    };
}

interface EventReportDates {
    yearStart: number;
    yearEnd: number;
    monthStart: number;
    monthEnd: number;
    quarterStart: number;
    quarterEnd: number;
}

function getEventReportDates(): EventReportDates {
    const now = moment();
    const monthStart = now.clone().startOf("month");
    const monthEnd = monthStart.clone().add(1, "month");
    const quarterStart = now.clone().startOf("quarter");
    const quarterEnd = quarterStart.clone().add(1, "quarter");
    const yearStart = now.clone().startOf("year");
    const yearEnd = yearStart.clone().add(1, "year");

    return {
        yearStart: yearStart.unix(),
        yearEnd: yearEnd.unix(),
        monthStart: monthStart.unix(),
        monthEnd: monthEnd.unix(),
        quarterStart: quarterStart.unix(),
        quarterEnd: quarterEnd.unix()
    };
}
