import React, {useEffect, useState} from "react";
import {CalendarEntryListRequest} from "../../../../api/grs";
import moment, {Moment} from "moment";
import DatePicker from "react-datepicker";
import {useHistory} from "react-router-dom";
import {useQuery} from "../../../Hooks/useQuery";
import {RequestFilterProps} from "../../../Filters/helpers/filterHelpers";
import DatePickerInputButton from "../../../Button/DatePickerInputButton";
import {useCalendarPageDetails} from "../../../Hooks/useCalendarPageDetails";

const AttendanceOverviewFilters = (props: RequestFilterProps<CalendarEntryListRequest>) => {
    const [startDate, setStartDate] = useState<Moment>();
    const history = useHistory();
    const query = useQuery();
    const {calendarId} = useCalendarPageDetails();

    /** Upon mounting, see if we can get query string exists, if so, set date from this. */
    useEffect(() => {
        const month = query.get("month");
        if (month) {
            setStartDate(moment.unix(+month));
            return;
        }
        setStartDate(moment().startOf("month"));
    }, []);

    /** Selected when the date changes */
    const onDateChanged = (date: Date | null) => {
        if (!date) return;

        setStartDate(moment(date));
    };

    /** Watches for changes to start date */
    useEffect(() => {
        if (!startDate) return;
        const request = buildCalendarEntryRequest(startDate);

        props.onRequestChanged(request);
    }, [startDate]);

    const buildCalendarEntryRequest = (start: Moment): CalendarEntryListRequest => {
        const startOfMonth = start.clone().startOf("month");
        const endOfMonth = start.clone().endOf("month");

        if (!calendarId) {
            return {
                startDate: startOfMonth.unix(),
                endDate: endOfMonth.unix()
            };
        }

        history.push({
            search: `?id=${calendarId}&month=${startOfMonth.unix()}`
        });

        return {
            startDate: startOfMonth.unix(),
            endDate: endOfMonth.unix(),
            calendarId: +calendarId
        };
    };

    return (
        <React.Fragment>
            <table className="filters-table mt-3">
                <tbody className="filters-lg">
                    <tr>
                        <th className="filter-heading w-50 pl-3 pr-3">Select Month</th>
                    </tr>
                    <tr>
                        <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                            {startDate && (
                                <DatePicker
                                    selected={startDate.toDate()}
                                    onChange={onDateChanged}
                                    showMonthYearPicker
                                    dateFormat="MMMM yyyy"
                                    portalId="root-portal"
                                    customInput={<DatePickerInputButton />}
                                />
                            )}
                        </td>
                    </tr>
                </tbody>
                <tbody className="filters-md">
                    <tr>
                        <th className="filter-heading w-50 pl-3 pr-3">Select Month</th>
                    </tr>
                    <tr>
                        <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                            {startDate && (
                                <DatePicker
                                    selected={startDate.toDate()}
                                    onChange={onDateChanged}
                                    showMonthYearPicker
                                    dateFormat="MMMM yyyy"
                                    portalId="root-portal"
                                    customInput={<DatePickerInputButton />}
                                />
                            )}
                        </td>
                    </tr>
                </tbody>
                <tbody className="filters-sm">
                    <tr>
                        <th className="filter-heading w-50 pl-3 pr-3">Select Month</th>
                    </tr>
                    <tr>
                        <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                            {startDate && (
                                <DatePicker
                                    selected={startDate.toDate()}
                                    onChange={onDateChanged}
                                    showMonthYearPicker
                                    dateFormat="MMMM yyyy"
                                    portalId="root-portal"
                                    customInput={<DatePickerInputButton />}
                                />
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
        </React.Fragment>
    );
};

export default AttendanceOverviewFilters;
