import React from "react";
import {nanoid} from "nanoid";

const Toggle = ({checked, onChange, size, disabled, name, value}: ToggleProps) => {
    const buttonId = nanoid();
    const toggleClassName = size === ToggleSize.Large ? "dmcheck" : "dmcheck-sm";

    return (
        <span className="toggle-control">
            <input
                className={toggleClassName}
                type="checkbox"
                checked={checked}
                onChange={onChange}
                id={buttonId}
                disabled={disabled}
                name={name}
                value={value}
            />
            <label htmlFor={buttonId} />
        </span>
    );
};

export default Toggle;

export interface ToggleProps {
    checked: boolean | undefined;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    size: ToggleSize;
    disabled?: boolean;
    name?: string;
    value?: string;
}

// eslint-disable-next-line no-shadow
export enum ToggleSize {
    Small = "Small",
    Large = "Large"
}
