import React from "react";
import {AutoInvoiceListResponse} from "../../../../../api/grs";
import FilterContainer from "../../../../Filters/FilterContainer";
import XeroInvoiceFilters from "./XeroInvoiceFilters";
import {useXeroInvoicesList} from "../Hooks/useXeroInvoicesList";
import TableActionContainer from "../../../../Table/TableActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../Button/MCButton";
import NoItemsMessage from "../../../../Table/NoItemsMessage";
import {useXeroInvoicesTable} from "../Hooks/useXeroInvoicesTable";
import InvoiceErrorsTableContainer from "./InvoiceList/Errors/InvoiceErrorsTableContainer";
import InvoiceLinesTableContainer from "./InvoiceList/Lines/InvoiceLinesTableContainer";
import ExpandingTableInnerRow from "../../../../../modules/Table/Components/ExpandingTableInnerRow";
import TableItemAction from "../../../../Table/TableItemAction";
import {IconType} from "../../../../Icon/Icon";
import {pagedRequestConfig} from "../../../../Filters/helpers/filterHelpers";
import DisconnectConfirmation from "../../Confirmation/DisconnectConfirmation";
import PulseExpandingTableWithServerPagination from "../../../../../modules/Table/PulseExpandingTableWithServerPagination";

function XeroInvoicesList(props: AutoInvoiceListResponse) {
    const {request, onRequestChanged, fetchData, generateInvoice} = useXeroInvoicesList();
    const {items, headers} = useXeroInvoicesTable(props);

    return (
        <React.Fragment>
            <div className="row ml-0 mr-0">
                <div className="col d-flex justify-content-end pr-0">
                    <DisconnectConfirmation
                        message={"Are you sure you want to disconnect from Xero?"}
                    />
                </div>
            </div>

            <FilterContainer closed={false}>
                <XeroInvoiceFilters request={request} onRequestChanged={onRequestChanged} />
            </FilterContainer>
            {request && (
                <div className="pt-3">
                    <TableActionContainer>
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Refresh"}
                            onClick={() => {
                                fetchData(request);
                            }}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                    </TableActionContainer>
                    <PulseExpandingTableWithServerPagination
                        resultsPerPage={pagedRequestConfig.resultsPerPage}
                        totalResults={props.totalInvoices}
                        initialPageNumber={request.pageNum}
                        onPageChanged={(newPageNum) => {
                            fetchData({
                                ...request,
                                pageNum: newPageNum
                            });
                        }}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        items={items}
                        headers={headers}
                        noItemsSection={
                            <NoItemsMessage
                                message={"There are no invoices for the selected month."}
                            />
                        }
                        customRenderers={{
                            staffName: ({staffName}: any) => staffName,
                            actions: ({actions}: any) => {
                                const {staffId, staffName} = actions;
                                return (
                                    <React.Fragment>
                                        <TableItemAction
                                            tooltipText={`Generate Invoice`}
                                            onClick={async (event) => {
                                                event.stopPropagation();

                                                await generateInvoice(staffId, staffName);
                                            }}
                                            icon={IconType.Invoice}
                                        />
                                    </React.Fragment>
                                );
                            }
                        }}
                        expandedRow={{
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            viewMoreRenderedItem: (item: any) => {
                                return (
                                    <React.Fragment>
                                        <InvoiceErrorsTableContainer {...item.actions.rawData} />
                                        <InvoiceLinesTableContainer {...item.actions.rawData} />
                                        <ExpandingTableInnerRow height={30} />
                                        <ExpandingTableInnerRow
                                            colSpan={5}
                                            height={5}
                                            className="expanded-under"
                                        />
                                    </React.Fragment>
                                );
                            }
                        }}
                        viewActionText={"invoice for staff member"}
                    />
                </div>
            )}
        </React.Fragment>
    );
}

export default XeroInvoicesList;
