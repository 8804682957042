import React from "react";
import {AssignmentState, EventCalendarEntry, FrontlineCalendarEntry} from "../../../../../api/grs";
import {getTotalAssignments} from "../../Helpers/calenderEntryHelpers";
import {IoCheckmarkCircle, IoCheckmarkDoneCircle} from "react-icons/io5";
import {FaUserCheck} from "react-icons/fa";

interface TopCalendarEntryStats {
    allocated: number;
    briefingAccepted: number;
    accepted: number;
    totalStaff: number;
}

export default function CalendarEntryStats(props: EventCalendarEntry | FrontlineCalendarEntry) {
    const stats = calculateEntryStats(props);

    return (
        <div className="calendar-entry-stats">
            <div className="entry-stat allocated">
                <div className="entry-stat-label">
                    <div className="entry-stat-icon">
                        <IoCheckmarkCircle />
                    </div>
                    <div className="entry-stat-value">{stats.allocated}</div>
                </div>
                <div className="entry-stat-details">
                    <div className="entry-stat-details-name">Staff Allocated</div>
                    <div className="entry-stat-details-value">
                        ({stats.allocated}/{stats.totalStaff})
                    </div>
                </div>
            </div>

            <div className="entry-stat accepted">
                <div className="entry-stat-label">
                    <div className="entry-stat-icon">
                        <IoCheckmarkDoneCircle />
                    </div>
                    <div className="entry-stat-value">{stats.accepted}</div>
                </div>
                <div className="entry-stat-details">
                    <div className="entry-stat-details-name">Staff Accepted</div>
                    <div className="entry-stat-details-value">
                        ({stats.accepted}/{stats.totalStaff})
                    </div>
                </div>
            </div>

            <div className="entry-stat briefing_accepted">
                <div className="entry-stat-label">
                    <div className="entry-stat-icon">
                        <FaUserCheck />
                    </div>
                    <div className="entry-stat-value">{stats.briefingAccepted}</div>
                </div>
                <div className="entry-stat-details">
                    <div className="entry-stat-details-name">Briefing Accepted</div>
                    <div className="entry-stat-details-value">
                        ({stats.briefingAccepted}/{stats.totalStaff})
                    </div>
                </div>
            </div>
        </div>
    );
}

function calculateEntryStats(
    entry: EventCalendarEntry | FrontlineCalendarEntry
): TopCalendarEntryStats {
    const totalAssignments = getTotalAssignments(entry);

    let allocated = 0;
    let briefingAccepted = 0;
    let accepted = 0;

    for (const assignment of totalAssignments) {
        if (assignment.state === AssignmentState.Unassigned) continue;
        ++allocated;

        switch (assignment.state) {
            case AssignmentState.Accepted:
            case AssignmentState.Attended:
                ++accepted;
                break;

            case AssignmentState.BriefingAccepted:
                ++briefingAccepted;
                ++accepted;
                break;
        }
    }

    return {
        allocated,
        briefingAccepted,
        accepted,
        totalStaff: totalAssignments.length
    };
}
