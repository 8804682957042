import React from "react";
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootStore} from "../../../../../../store/Store";
import {NavigationItem} from "../../../../../../store/navigation/actions/NavigationActionTypes";
import {useUserData} from "../../../../../Hooks/useUserData";

function extractPath(url: string): string {
    const pos = url.indexOf("?");
    if (pos === -1) return url;

    return url.substring(0, pos);
}

function HeadTab({tab}: {tab: NavigationItem}) {
    const location = useLocation();
    const path = tab.path;
    if (!path) return null;

    const active = extractPath(path) === location.pathname;
    const className = active ? "head-tab active" : "head-tab";

    return (
        <a className={className} href={path}>
            {tab.name}
        </a>
    );
}

export default function HeadTabs() {
    const user = useUserData();
    const navItems = useSelector((state: RootStore) => state.navigation.data) || [];
    const tabs = navItems.filter((x) => x.tabsOnly);

    if (tabs.length === 0) return null;

    return (
        <div className="head-tabs">
            {tabs.map((tab) => {
                if (tab.superAdminOnly && !user.superAdmin) return null;
                return <HeadTab key={tab.path} tab={tab} />;
            })}
        </div>
    );
}
