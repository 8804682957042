import React, {useState} from "react";
import {
    CalendarEntry,
    StaffBlockTemplate,
    StaffBlockTemplateListEntry
} from "../../../../../../api/grs";
import StaffBlockDropdown from "../../../../../Dropdown/Components/StaffBlockDropdown";
import {useDispatch} from "react-redux";
import {getGlobalStaffBlock} from "../../../../../../store/globalStaffBlock/actions/GlobalStaffBlockActions";
import {setCalendarEntry} from "../../../../../../store/calendarEntry/actions/CalendarEntryActions";
import {IconType} from "../../../../../Icon/Icon";
import {showErrorToast} from "../../../../../../utils/toastUtils";
import {Modal, useModal} from "pulse-modal";
import FormRowWithTooltip from "../../../../../Form/FormRowWithTooltip";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../Button/MCButton";

const CalendarEntryStaffBlockOverride = (props: CalendarEntry) => {
    const [staffBlockId, setStaffBlockId] = useState<number>(0);
    const {toggle, isShown} = useModal();
    const dispatch = useDispatch();

    const onBlockChanged = (item: StaffBlockTemplateListEntry | undefined) => {
        const id = item ? item.id : 0;
        setStaffBlockId(id);
    };

    const onStaffBlockOverrideAdded = async () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const staffBlockTemplate: StaffBlockTemplate = await dispatch(
            getGlobalStaffBlock(staffBlockId)
        );

        if (!staffBlockTemplate) {
            showErrorToast("Could not get selected staff block");
            return;
        }

        dispatch(
            setCalendarEntry({
                ...props,
                requiredStaff: staffBlockTemplate.staffBlock
            })
        );
        toggle();

        setStaffBlockId(0);
    };

    const tooltipText = () => {
        return `This is an optional field. This will load in a specified staff block template which will override the staff block for this calendar entry.`;
    };
    return (
        <React.Fragment>
            <div className="row ml-0 mr-0 mt-3">
                <div className="col-sm-6 calendar-entry-input-override pl-0">
                    <FormRowWithTooltip
                        columnDetailClassName={"pl-0 pr-0"}
                        rowName={"Staff Block Override"}
                        icon={IconType.Question}
                        tooltipText={tooltipText()}
                    >
                        <div className="d-inline-block ">
                            <StaffBlockDropdown
                                searchable={true}
                                changeOption={onBlockChanged}
                                clearable={true}
                                id={staffBlockId}
                                disabled={false}
                            />
                        </div>
                        <div className="d-inline-block ml-4">
                            {staffBlockId > 0 && (
                                <MCButton
                                    size={ButtonSize.Large}
                                    innerValue={"Apply"}
                                    onClick={toggle}
                                    colour={ButtonColourOptions.Yellow}
                                    roundedCorner
                                />
                            )}
                        </div>
                    </FormRowWithTooltip>
                </div>
            </div>
            <Modal
                isShown={isShown}
                onClose={toggle}
                title={"Apply new staff block"}
                modalSize={"sm"}
                bodyChildren={
                    <React.Fragment>
                        <div className="row ml-0 mr-0">
                            <p className={"mb-0"}>
                                Are you sure you want to apply this staff block override? All
                                assignments/sections/groupings will be reset.
                            </p>
                        </div>
                    </React.Fragment>
                }
                footerChildren={
                    <React.Fragment>
                        <div className="row ml-0 mr-0">
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"Yes"}
                                onClick={onStaffBlockOverrideAdded}
                                colour={ButtonColourOptions.Yellow}
                                roundedCorner
                            />
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"No"}
                                onClick={toggle}
                                colour={ButtonColourOptions.DarkBlue}
                                roundedCorner
                            />
                        </div>
                    </React.Fragment>
                }
            />
        </React.Fragment>
    );
};

export default CalendarEntryStaffBlockOverride;
