import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useUserData} from "../../Hooks/useUserData";
import CalendarGridWrapper from "./Components/CalendarGridWrapper";
import {WithServiceState} from "store-fetch-wrappers/dist";
import {RootStore} from "../../../store/Store";
import {getCalendar, nullifyCalendarStore} from "../../../store/calendar/actions/CalendarActions";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../Navigation/routeNames";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {usePageUrl} from "../../Hooks/usePageUrl";
import {nullifyCalendarEntriesListStore} from "../../../store/calendarEntryList/actions/CalendarEntryListActions";
import {fetchAllVenues} from "../../../store/venueList/actions/VenueListActions";
import {getVenuesListPath} from "../VenueList/VenueList";
import {useCalendarPageDetails} from "../../Hooks/useCalendarPageDetails";
import {getEventReportPath} from "../Reports/EventReport/Helpers/eventReportHelpers";

const CalendarServiceWrapper = WithServiceState(CalendarGridWrapper);

const AdminCalendar = () => {
    const dispatch = useDispatch();
    const user = useUserData();
    const {fullPath} = usePageUrl();
    const {calendarId, calendarName} = useCalendarPageDetails();

    const calendarStore = useSelector((state: RootStore) => state.calendar);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useEffect(() => {
        dispatch(fetchAllVenues());
        if (!calendarId) return;

        dispatch(getCalendar(+calendarId));

        return async function () {
            dispatch(nullifyCalendarStore());
            dispatch(nullifyCalendarEntriesListStore());
        };
    }, []);

    useEffect(() => {
        if (!user.username) return;

        setUpRoutes();
    }, [user]);

    /** Sets up routes */
    const setUpRoutes = () => {
        if (!calendarId) return;
        const navigationItems: NavigationItem[] = [
            {
                name: routeNames.groupRosteringSystem.name,
                path: routeNames.groupRosteringSystem.path
            },
            {
                name: calendarName,
                path: fullPath,
                children: [
                    {
                        name: routeNames.eventReport.name,
                        path: getEventReportPath(calendarName, +calendarId)
                    },
                    {
                        name: routeNames.venueList.name,
                        superAdminOnly: true,
                        path: getVenuesListPath(calendarName, +calendarId)
                    }
                ]
            }
        ];

        dispatch(setNavigationItems(navigationItems));
    };

    return (
        <React.Fragment>
            <div className="page-container pb-4">
                <CalendarServiceWrapper
                    showLoadingText={true}
                    loaderType={"overlay"}
                    loaderWheelType={"three-ring"}
                    placeholderBlockCount={1}
                    {...calendarStore}
                />
            </div>
        </React.Fragment>
    );
};

export default AdminCalendar;
