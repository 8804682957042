import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {routeNames} from "../../Navigation/routeNames";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {useQuery} from "../../Hooks/useQuery";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {
    createNewCalendarEntry,
    nullifyCalendarEntryStore
} from "../../../store/calendarEntry/actions/CalendarEntryActions";
import {RootStore} from "../../../store/Store";
import {nullifyCalendarStore} from "../../../store/calendar/actions/CalendarActions";
import {Calendar} from "../../../api/grs";
import {
    getAddCalendarEntryPath,
    getPathToCalendarFromCalendarEntry
} from "./Helpers/calenderEntryHelpers";
import {getVenuesListPath} from "../VenueList/VenueList";
import {WithServiceState} from "store-fetch-wrappers/dist";
import EditCalendarEntryForm from "./Components/EditCalendarEntryForm";
import HeadTabs from "../Layouts/Layout/Components/HeadTabs/HeadTabs";
const ServiceWrapper = WithServiceState(EditCalendarEntryForm);

/** Parent component for Adding a calendar Entry */
const AddCalendarEntry = () => {
    const dispatch = useDispatch();
    const query = useQuery();

    const calendarEntryStore = useSelector((state: RootStore) => state.calendarEntry);
    const calendarStore = useSelector((state: RootStore) => state.calendar);

    useEffect(() => {
        const calendarId = query.get("calendarId");
        if (!calendarId) return;
        const startDate = query.get("startDate");
        if (!startDate) return;

        //Create a new calendar entry upon mounting
        dispatch(createNewCalendarEntry(+calendarId, +startDate));

        //Clean the stale data from the store.
        return function () {
            dispatch(nullifyCalendarStore());
            dispatch(nullifyCalendarEntryStore());
        };
    }, []);

    useEffect(() => {
        const startDate = query.get("startDate");
        if (!startDate) return;
        if (calendarStore.loading) return;
        if (!calendarStore.data) return;

        setUpRoutes(calendarStore.data, +startDate);
    }, [calendarStore.data, calendarStore.loading]);

    /** Set up routes for Add Calendar Entry Route */
    const setUpRoutes = (calendar: Calendar, startDate: number) => {
        const navigationItems: NavigationItem[] = [
            {
                name: routeNames.groupRosteringSystem.name,
                path: routeNames.groupRosteringSystem.path
            },
            {
                name: calendar.name,
                path: getPathToCalendarFromCalendarEntry(calendar)
            },
            {
                name: routeNames.addCalendarEntry.name,
                tabsOnly: true,
                path: getAddCalendarEntryPath(calendar, startDate)
            },
            {
                name: routeNames.venueList.name,
                tabsOnly: true,
                superAdminOnly: true,
                path: getVenuesListPath(calendar.name, calendar.id)
            }
        ];
        dispatch(setNavigationItems(navigationItems));
    };

    return (
        <React.Fragment>
            <HeadTabs />
            <ServiceWrapper
                showLoadingText={true}
                loaderType={"overlay"}
                loaderWheelType={"three-ring"}
                placeholderBlockCount={1}
                {...calendarEntryStore}
            />
        </React.Fragment>
    );
};

export default AddCalendarEntry;
