//Getting enum as a generic. Accepts string/numeric/heterogeneous
import {BillingType, CalendarTypeEnum, ClinicalGrade, VenueStateEnum} from "../api/grs";
import {ArchivedView} from "../components/Pages/GroupRosteringSystem/Slices/Components/EventCalendarFilters";
import {ActiveShiftView} from "../components/Pages/AvailableShiftsList/Provider/ShiftOverviewProvider";

export type Enum<E> = Record<keyof E, number | string> & {[k: number]: string};

/** Gets Clinical Grade from string */
export function getClinicalGradeFromString(value: string): ClinicalGrade {
    return ClinicalGrade[value as keyof typeof ClinicalGrade];
}

/** Gets Calendar Type from string */
export function getCalenderTypeFromString(value: string): CalendarTypeEnum {
    return CalendarTypeEnum[value as keyof typeof CalendarTypeEnum];
}

/** Gets Billing Type from string */
export function getBillingTypeFromString(value: string): BillingType {
    return BillingType[value as keyof typeof BillingType];
}

/** Gets the venue state enum from string */
export function getVenueStateFromString(value: string): VenueStateEnum {
    return VenueStateEnum[value as keyof typeof VenueStateEnum];
}

export function getActiveShiftViewFromString(value: string): ActiveShiftView {
    return ActiveShiftView[value as keyof typeof ActiveShiftView];
}

export function getArchiveFilterFromString(value: string): ArchivedView {
    return ArchivedView[value as keyof typeof ArchivedView];
}
