import React, {useEffect, useState} from "react";
import {RequestFilterProps} from "../../../../Filters/helpers/filterHelpers";
import {StaffWageReportRequest} from "../../../../../api/grs";
import moment, {Moment} from "moment";
import DatePickerInputButton from "../../../../Button/DatePickerInputButton";
import DatePicker from "react-datepicker";

const WageReportFilters = (props: RequestFilterProps<StaffWageReportRequest>) => {
    const [startDate, setStartDate] = useState<Moment>(moment());
    /** Selected when the date changes */
    const onDateChanged = (date: Date | null) => {
        if (!date) return;

        setStartDate(moment(date));
    };

    // Watch for changes before emitting to parent
    useEffect(() => {
        if (!startDate) return;

        props.onRequestChanged({
            startDateInclusive: startDate.clone().startOf("month").unix(),
            endDateExclusive: startDate.clone().endOf("month").unix()
        });
    }, [startDate]);

    return (
        <React.Fragment>
            <table className="filters-table mt-3">
                <tbody className="filters-lg">
                    <tr>
                        <th className="filter-heading w-50 pl-3 pr-3">Select Date</th>
                    </tr>
                    <tr>
                        <th className="filter-heading w-50 pl-3 pr-3 pb-3">
                            <DatePicker
                                selected={startDate.toDate()}
                                onChange={onDateChanged}
                                showMonthYearPicker
                                dateFormat="MMMM yyyy"
                                portalId="root-portal"
                                customInput={<DatePickerInputButton />}
                            />
                        </th>
                    </tr>
                </tbody>
                <tbody className="filters-md">
                    <tr>
                        <th className="filter-heading w-50 pl-3 pr-3">Select Date</th>
                    </tr>
                    <tr>
                        <th className="filter-heading w-50 pl-3 pr-3 pb-3">
                            <DatePicker
                                selected={startDate.toDate()}
                                onChange={onDateChanged}
                                showMonthYearPicker
                                dateFormat="MMMM yyyy"
                                portalId="root-portal"
                                customInput={<DatePickerInputButton />}
                            />
                        </th>
                    </tr>
                </tbody>
                <tbody className="filters-sm">
                    <tr>
                        <th className="filter-heading w-100 pl-3 pr-3">Select Date</th>
                    </tr>
                    <tr>
                        <th className="filter-heading w-100 pl-3 pr-3 pb-3">
                            <DatePicker
                                selected={startDate.toDate()}
                                onChange={onDateChanged}
                                showMonthYearPicker
                                dateFormat="MMMM yyyy"
                                portalId="root-portal"
                                customInput={<DatePickerInputButton />}
                            />
                        </th>
                    </tr>
                </tbody>
            </table>
        </React.Fragment>
    );
};

export default WageReportFilters;
