import React from "react";
import {ClinicalGrade, StaffAssignment, StaffAssignmentGrouping} from "../../../../../../api/grs";
import {nanoid} from "nanoid";
import FormHeader from "../../../../../Form/FormHeader";
import Icon, {IconType} from "../../../../../Icon/Icon";
import Tooltip from "../../../../../Tooltip/Tooltip";

const AddCalendarGrouping = (props: AddCalendarGroupingProps) => {
    /** Adds group to section */
    const addGrouping = () => {
        const args: StaffAssignmentGrouping = {
            id: nanoid(),
            name: "New Group",
            clinicalGrades: Array<ClinicalGrade>(),
            assignments: Array<StaffAssignment>()
        };

        props.onGroupAdded(args);
    };

    return (
        <React.Fragment>
            <div className="calendar-group-card float-left">
                <FormHeader className={"text-center"} headerName={"Add Group"} />
                <div className="row mt-5 pt-5">
                    <div className="col d-flex justify-content-center mt-5">
                        <Tooltip tooltipText={"Add Grouping"} place={"bottom"} size={"lg"}>
                            <Icon
                                className={"cursor-pointer"}
                                rootElement={"Span"}
                                icon={IconType.Add}
                                onClick={addGrouping}
                                size={"XX-Large"}
                            />
                        </Tooltip>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AddCalendarGrouping;

interface AddCalendarGroupingProps {
    onGroupAdded: (newGroup: StaffAssignmentGrouping) => void;
}
