import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {StaffAssignmentGrouping} from "../../../../../../../../api/grs";
import {nanoid} from "nanoid";
import {GenericNameValueToggle} from "../../../../../../../../utils/sortingUtils";
import {getClinicalGradeToggleOptionsFromClinicalGrades} from "../../../../../../GlobalStaffBlock/AddEdit/Helpers/staffBlockHelpers";
import {Modal, useModal} from "pulse-modal";
import {updateStaffGroupingClinicalGrades} from "../../../../../../CalendarForm/Helpers/calendarFormHelpers";
import {addStaffAssignmentGrouping} from "../../../../../../../../store/calendarEntry/actions/CalendarEntryActions";
import Icon, {IconType} from "../../../../../../../Icon/Icon";
import Tooltip from "../../../../../../../Tooltip/Tooltip";
import FormRow from "../../../../../../../Form/FormRow";
import {DebounceInput} from "react-debounce-input";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../../../Button/MCButton";
import {showErrorToast} from "../../../../../../../../utils/toastUtils";

/** Component which controls the functionality to add a Group to a Section */
const AddCalendarEntryGroup = (props: AddCalendarEntryGroupProps) => {
    const dispatch = useDispatch();
    const [group, setGroup] = useState<StaffAssignmentGrouping>({
        clinicalGrades: [],
        assignments: [],
        id: nanoid(),
        name: ""
    });
    const [clinicalGrades, setClinicalGrades] = useState<GenericNameValueToggle[]>(
        getClinicalGradeToggleOptionsFromClinicalGrades([])
    );
    const {isShown, toggle} = useModal();

    /** Updates the toggled option of the clinical grade for the group */
    const changeToggledOption = (value: GenericNameValueToggle) => {
        const newDetails = updateStaffGroupingClinicalGrades(group, value);
        setGroup(newDetails);
    };

    /** Anytime the group is altered, we update the clinical grades. */
    useEffect(() => {
        const toggleFieldProps = getClinicalGradeToggleOptionsFromClinicalGrades(
            group.clinicalGrades
        );
        setClinicalGrades(toggleFieldProps);
    }, [group]);

    /** Edit Group Name */
    const editGroupName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGroup({
            ...group,
            name: event.target.value
        });
    };

    /** Reset Group */
    const resetGroupDetails = () => {
        setGroup({
            clinicalGrades: [],
            assignments: [],
            id: nanoid(),
            name: ""
        });
        setClinicalGrades(getClinicalGradeToggleOptionsFromClinicalGrades([]));
        toggle();
    };

    /** Adds group to section */
    const addGroup = async () => {
        if (group.name.length < 3) {
            showErrorToast("Group must be more than 3 characters");
            return;
        }
        await dispatch(addStaffAssignmentGrouping({sectionId: props.sectionId, group}));
        toggle();
    };
    return (
        <React.Fragment>
            <div className="row mr-0 ml-0">
                <div className="col">
                    <h6 className="group_titles">
                        Add Group
                        <Tooltip tooltipText={"Add Group"} place={"right"} size={"lg"}>
                            <Icon
                                className={"cursor-pointer"}
                                rootElement={"Span"}
                                icon={IconType.Add}
                                onClick={resetGroupDetails}
                                size={"Large"}
                            />
                        </Tooltip>
                    </h6>
                </div>
            </div>
            <Modal
                isShown={isShown}
                onClose={toggle}
                title={"Add Group"}
                modalSize={"sm"}
                bodyChildren={
                    <React.Fragment>
                        <FormRow rowName={"New Group Name"} columnDetailClassName={"pl-0 pr-0"}>
                            <DebounceInput
                                debounceTimeout={300}
                                placeholder={"Enter new group name here..."}
                                className="input-fields"
                                value={group.name}
                                onChange={editGroupName}
                            />
                        </FormRow>
                        <FormRow
                            rowName={"Group Clinical Grades"}
                            columnDetailClassName={"pl-1 pr-1"}
                        >
                            {clinicalGrades.map((el: GenericNameValueToggle, index: number) => {
                                return (
                                    <div className="row ml-0 mr-0" key={index}>
                                        <label className={"cursor-pointer"}>
                                            <input
                                                type="checkbox"
                                                name={el.name}
                                                value={el.value}
                                                onChange={() => changeToggledOption(el)}
                                                checked={el.checked}
                                            />
                                            <span className="ml-2">{el.name} </span>
                                        </label>
                                    </div>
                                );
                            })}
                        </FormRow>
                    </React.Fragment>
                }
                footerChildren={
                    <React.Fragment>
                        <div className="row ml-0 mr-0">
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"Save"}
                                onClick={addGroup}
                                colour={ButtonColourOptions.Yellow}
                                roundedCorner
                            />
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"Cancel"}
                                onClick={toggle}
                                colour={ButtonColourOptions.DarkBlue}
                                roundedCorner
                            />
                        </div>
                    </React.Fragment>
                }
            />
        </React.Fragment>
    );
};

export default AddCalendarEntryGroup;

interface AddCalendarEntryGroupProps {
    sectionId: string;
}
