import React, {useState} from "react";
import {
    AssignmentState,
    CalendarSummaryStats,
    StaffReportRequest,
    StaffReportResponse
} from "../../../../../api/grs";
import FilterContainer from "../../../../Filters/FilterContainer";
import StaffReportFilters from "./StaffReportFilters";
import {useDispatch, useSelector} from "react-redux";
import {getStaffMemberAssignmentsReport} from "../../../../../store/staffReport/actions/StaffReportActions";
import {RootStore} from "../../../../../store/Store";
import {formatUnixToDDMMYYYY, formatUnixToMMMMYYYY} from "../../../../../utils/momentUtils";
import {PulseTable} from "pulse_table";
import ShiftActions from "../../../../Notifications/ShiftActions";
import StaffReportStats from "./StaffReportStats";
import FormActionContainer from "../../../../Form/FormActionContainer";
import AuthAmI from "../../../../AuthAmI/AuthAmI";
import {StaffAccessLevel, UserData} from "../../../../../api/staff";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../Button/MCButton";
import {staffAssignmentReportFile} from "../../../../../utils/reportUtils";
import {jsonToCsv} from "../../../../../utils/csvUtils";
import {useUserData} from "../../../../Hooks/useUserData";

import jsPDF from "jspdf";
import {
    getBriefingAccepted,
    getEndDate,
    getShiftAccepted,
    getShiftDetails,
    getStaffWage,
    getStartDate
} from "../Helpers/staffReportHelpers";
import {printStaffReportPdf} from "../Helpers/staffReportPdfHelpers";
import {magistralFont} from "../Helpers/Fonts/Magistral-Bold-normal";
import {montserratRegularFont} from "../Helpers/Fonts/Montserrat-Regular-normal";

const StaffReportTable = (props: StaffReportResponse) => {
    const dispatch = useDispatch();

    const [staffReportRequest, setStaffReportRequest] = useState<StaffReportRequest>();
    const summaries = useSelector((state: RootStore) => state.calendarSummaryStats.data || []);
    const staffList = useSelector((state: RootStore) => state.staffList.data || []);

    //Used for Non Admin actions
    const user = useUserData();

    const onRequestChanged = (request: StaffReportRequest) => {
        if (!staffReportRequest) {
            setStaffReportRequest(request);
            fetchStaffReport(request);
            return;
        }
        if (JSON.stringify(request) === JSON.stringify(staffReportRequest)) return;
        setStaffReportRequest(request);
        fetchStaffReport(request);
    };

    const fetchStaffReport = (request: StaffReportRequest) => {
        dispatch(getStaffMemberAssignmentsReport(request));
    };

    const downloadReport = () => {
        if (!staffList) return;

        const selectedUser = staffList.find((el: UserData) => el.username === props.staffId);
        if (!selectedUser) return;
        //Get their details based on the index.
        const currentDate = formatUnixToMMMMYYYY(props.startDateInclusive);

        const fileName = `${selectedUser.firstName} ${selectedUser.lastName} - Staff Report - ${currentDate}.csv`;

        const report = staffAssignmentReportFile(props.entries, summaries);

        jsonToCsv(fileName, report);
    };

    function getPrintableReportHeaderName() {
        const currentDate = formatUnixToMMMMYYYY(props.startDateInclusive);
        if (user.username.length === 0) return `Staff Report - ${currentDate}`;

        switch (user.accessLevel) {
            case StaffAccessLevel.SystemAdministrator:
                const selectedUser = staffList.find(
                    (el: UserData) => el.username === props.staffId
                );
                if (!selectedUser) return `Staff Report - ${currentDate}`;
                return `${getFullName(selectedUser)} - Staff Report - ${currentDate}`;
            case StaffAccessLevel.DutyManager:
            case StaffAccessLevel.Staff:
                return `${getFullName(user)} - Staff Report - ${currentDate}`;
        }
    }

    function downloadPdf() {
        const doc = new jsPDF();
        doc.addFileToVFS("Magistral-Bold-normal.ttf", magistralFont);
        doc.addFont("Magistral-Bold-normal.ttf", "Magistral", "normal");
        doc.addFileToVFS("Montserrat-Regular-normal.ttf", montserratRegularFont);
        doc.addFont("Montserrat-Regular-normal.ttf", "Montserrat-Regular", "normal");

        printStaffReportPdf(props, summaries, getPrintableReportHeaderName());
    }

    return (
        <React.Fragment>
            <FilterContainer closed={false}>
                <StaffReportFilters onRequestChanged={onRequestChanged} />
            </FilterContainer>
            <div className="mt-4">
                <PulseTable
                    items={toTableRow(props, summaries)}
                    headers={{
                        shiftDetails: "Shift Details",
                        date: "Date",
                        shiftAccepted: "Shift Accepted",
                        briefingAccepted: "Briefing Accepted",
                        start: "Start",
                        end: "End",
                        wage: "Wage",
                        actions: "Actions"
                    }}
                    customRenderers={{
                        actions: (item: TableRow) => (
                            <React.Fragment>
                                <ShiftActions
                                    {...item.actions}
                                    staffId={staffReportRequest?.staffId || ""}
                                    callback={() => {
                                        if (!staffReportRequest) return;
                                        fetchStaffReport(staffReportRequest);
                                    }}
                                />
                            </React.Fragment>
                        )
                    }}
                    noItemsSection={
                        <div className="row ml-0 mr-0 fadeIn">
                            <div className="col">
                                <h5 className="text-center p-3">
                                    Staff member not selected, or no staff reports for this month.
                                </h5>
                            </div>
                        </div>
                    }
                />
            </div>
            <StaffReportStats {...props} />
            {props.entries.length > 0 && (
                <FormActionContainer>
                    <div className="col d-flex justify-content-end pr-0 pl-0">
                        <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"Download CSV"}
                                onClick={downloadReport}
                                colour={ButtonColourOptions.Yellow}
                                roundedCorner
                            />
                        </AuthAmI>
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Download PDF"}
                            onClick={downloadPdf}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                    </div>
                </FormActionContainer>
            )}
        </React.Fragment>
    );
};

export default StaffReportTable;

interface TableRow {
    shiftDetails: string;
    date: string;
    shiftAccepted: string;
    briefingAccepted: string;
    start: string;
    end: string;
    wage: string;
    actions: TableRowActions;
}

interface TableRowActions {
    entryId: number;
    date: number;
    state: AssignmentState;
}

function toTableRow(
    resp: StaffReportResponse,
    calendarSummaries: CalendarSummaryStats[]
): TableRow[] {
    return resp.entries.map((item) => {
        return {
            shiftDetails: getShiftDetails(item, calendarSummaries),
            date: formatUnixToDDMMYYYY(item.entryStartDate),
            shiftAccepted: getShiftAccepted(item),
            briefingAccepted: getBriefingAccepted(item),
            start: getStartDate(item),
            end: getEndDate(item),
            wage: getStaffWage(item),
            actions: {
                entryId: item.entryId,
                date: item.entryStartDate,
                state: item.state
            }
        };
    });
}

function getFullName({firstName, lastName}: UserData): string {
    return `${firstName} ${lastName}`;
}
