import React, {useContext} from "react";
import {CalendarTypeEnum, EventCalendarEntry, FrontlineCalendarEntry} from "../../../../api/grs";
import {useDispatch, useSelector} from "react-redux";
import CalendarEntryGeneralDetails from "./Admin/General/CalendarEntryGeneralDetails";
import CalendarEntryDetails from "./Admin/EventDetails/CalendarEntryDetails";
import CalendarEntryStaffManagement from "./Admin/StaffManagement/CalendarEntryStaffManagement";
import BackgroundContainer from "../../../Containers/BackgroundContainer";
import {CalendarEntryContext} from "../../../Context/CalendarEntryContext";
import {RootStore} from "../../../../store/Store";
import {StaffListContext} from "../../../Context/StaffListContext";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import {useHistory, useParams} from "react-router-dom";
import {useQuery} from "../../../Hooks/useQuery";
import {routeNames} from "../../../Navigation/routeNames";
import {saveCalendarEntry} from "../../../../store/calendarEntry/actions/CalendarEntryActions";
import {useIsEntryHistoric} from "../../../Hooks/useIsEntryHistoric";
import DeleteCalendarEntry from "./Admin/Actions/Components/DeleteCalendarEntry";
import {showSuccessToast} from "../../../../utils/toastUtils";
import RescheduleCalendarEntry from "./Admin/Actions/Components/RescheduleCalendarEntry";
import CalendarEntryStats from "./Stats/CalendarEntryStats";
import CalendarEntryHeader from "./Admin/General/CalendarEntryHeader";
import SMSButton from "../../../SMS/Components/SMSButton";
import {ShowAdvancedControlsContext} from "../../../../contexts/advancedControls";
import InterestedStaffButton from "./Admin/Actions/Components/InterestedStaff/InterestedStaffButton";
import DutyManagerReportButton from "../../ReadOnlyCalendarEntry/Components/DutyManagerReportButton";
import RepeatEntryButton from "./Admin/Actions/Components/RepeatEntry/RepeatEntryButton";

const EditCalendarEntryForm = (props: EventCalendarEntry | FrontlineCalendarEntry) => {
    const dispatch = useDispatch();
    const showAdvancedContext = useContext(ShowAdvancedControlsContext);
    const entry = useContext(CalendarEntryContext);
    const staffListStore = useSelector((state: RootStore) => state.staffList);
    const history = useHistory();
    const query = useQuery();
    const params: any = useParams();
    const isHistoric = useIsEntryHistoric(props);

    const saveEntry = async () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(saveCalendarEntry(props));
        if (!success) {
            return;
        }
        showSuccessToast("Saved entry");
        goBackToLastScreen();
    };

    const goBackToLastScreen = () => {
        const previousLocation = localStorage.getItem("calendar_redirect");
        if (previousLocation) {
            history.replace(previousLocation);
            return;
        }

        const path = getCalendarPath(params.calendarName);
        history.replace(path);
    };

    /** Gets the path back to the calendar on fallback */
    const getCalendarPath = (calendarName: string) => {
        const calendarId = query.get("calendarId");
        const month = query.get("month");
        const week = query.get("week");

        if (month) {
            return `${routeNames.calendar.path}/${encodeURI(
                calendarName
            )}?id=${calendarId}&month=${month}`;
        }
        if (week) {
            return `${routeNames.calendar.path}/${encodeURI(
                calendarName
            )}?id=${calendarId}&week=${week}`;
        }

        return "";
    };
    return (
        <div className="edit-calendar-entry">
            <React.Fragment>
                <BackgroundContainer background={"background-stars"} pageContainerClass={"pt-4"}>
                    <CalendarEntryContext.Provider value={props}>
                        {staffListStore.data && (
                            <StaffListContext.Provider value={staffListStore.data}>
                                <div className="edit-calendar-calendar-header">
                                    <CalendarEntryHeader {...props} />
                                    <CalendarEntryStats {...props} />
                                    <CalendarEntryGeneralDetails {...props} />
                                </div>
                                <div className="edit-calendar-event-details">
                                    <CalendarEntryDetails {...props} />
                                </div>
                                <div className="edit-calendar-staff-management">
                                    <CalendarEntryStaffManagement {...props} />
                                </div>
                                <div className="edit-calendar-shift-actions">
                                    <div className="row ml-0 mr-0 mt-3 edit-calendar-buttons">
                                        <div className="edit-calendar-controls">
                                            <SMSButton />
                                            {showAdvancedContext?.show && (
                                                <>
                                                    {entry.calendarType ===
                                                        CalendarTypeEnum.Event && (
                                                        <DutyManagerReportButton />
                                                    )}
                                                    <RepeatEntryButton />
                                                    <InterestedStaffButton />
                                                    {props.id > 0 && !isHistoric && (
                                                        <RescheduleCalendarEntry />
                                                    )}
                                                </>
                                            )}
                                        </div>
                                        <div className="edit-calendar-actions">
                                            <MCButton
                                                size={ButtonSize.Large}
                                                innerValue="Save"
                                                onClick={saveEntry}
                                                colour={ButtonColourOptions.Yellow}
                                                className="mr-3 save"
                                                roundedCorner
                                            />
                                            <MCButton
                                                size={ButtonSize.Large}
                                                innerValue="Cancel"
                                                onClick={goBackToLastScreen}
                                                colour={ButtonColourOptions.DarkBlue}
                                                className="mr-3"
                                                roundedCorner
                                            />
                                            {props.id > 0 && !isHistoric && (
                                                <DeleteCalendarEntry
                                                    onActionSuccess={goBackToLastScreen}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </StaffListContext.Provider>
                        )}
                    </CalendarEntryContext.Provider>
                </BackgroundContainer>
            </React.Fragment>
        </div>
    );
};

export default EditCalendarEntryForm;
