import React from "react";
import {CalendarComplianceLevel, CalendarGroup, CalendarTypeEnum} from "../../../../api/grs";
import BasicItemList, {BasicItemListItem} from "./BasicItemList";
import {FaCircleCheck} from "react-icons/fa6";
import {HiExclamationTriangle} from "react-icons/hi2";
import {AiFillCloseSquare} from "react-icons/ai";
import {Link, useLocation} from "react-router-dom";
import {routeNames} from "../../../Navigation/routeNames";
import moment from "moment";
import {getMonthWeekQueryString} from "../../AdminCalendar/Helpers/calendarUtils";
import {TbPencil} from "react-icons/tb";
import {CalendarComplianceBasic} from "../Logic/compliance";
import OverallStats from "./OverallStats";
import {getGlobalCalendarUrlForGroup} from "../../../../utils/urls";

export interface ComplianceBox {
    name: string;
    group: CalendarGroup | undefined;
    data: CalendarComplianceBasic[];
}

export interface GroupComplianceWidgetProps {
    box: ComplianceBox;
}

export default function GroupComplianceWidget(props: GroupComplianceWidgetProps) {
    const location = useLocation();
    const rows = getBasicItems(props.box.data, location.search);
    const calendarUrl = props.box.group ? getGlobalCalendarUrlForGroup(props.box.group) : null;

    return (
        <div className="dash-widget">
            <div className="dash-widget-split-header">
                <div>
                    <h2>{props.box.name}</h2>
                    <div className="dash-widget-subtitle">
                        {rows.length} Calendar{rows.length > 1 ? "s" : ""}
                    </div>
                </div>
                <div>
                    {calendarUrl && (
                        <Link className="dash-widget-more" to={calendarUrl}>
                            View Calendar
                        </Link>
                    )}
                </div>
            </div>

            <OverallStats compliance={props.box.data} />

            <div className="dash-widget-basic-stats">
                <BasicItemList items={rows} />
            </div>
        </div>
    );
}

interface ComplianceStateBadgeProps {
    compliant: CalendarComplianceLevel;
    editUrl: string;
    calendarUrl: string;
}

function ComplianceStateBadge(props: ComplianceStateBadgeProps) {
    let icon = null;
    let text = "Filled";
    const className = `widget-compliance ${props.compliant.toLowerCase()}`;

    switch (props.compliant) {
        case CalendarComplianceLevel.Compliant:
            icon = <FaCircleCheck />;
            text = "Filled";
            break;

        case CalendarComplianceLevel.PartialCompliant:
            icon = <HiExclamationTriangle />;
            text = "Part Filled";
            break;
        case CalendarComplianceLevel.NotCompliant:
            icon = <AiFillCloseSquare />;
            text = "Not Filled";
            break;

        case CalendarComplianceLevel.NotApplicable:
            text = "Not Applicable";
            break;
    }

    return (
        <Link to={props.calendarUrl}>
            <div className={className}>
                {icon && <div className="widget-compliance-icon">{icon}</div>}
                <div className="widget-compliance-text">{text}</div>
            </div>
        </Link>
    );
}

interface CalendarNameViewProps {
    name: string;
    editUrl: string;
    calendarUrl: string;
}

function CalendarNameView(props: CalendarNameViewProps) {
    return (
        <div className="widget-compliance-name">
            <Link to={props.editUrl}>
                <TbPencil />
            </Link>
            <Link to={props.calendarUrl}>{props.name}</Link>
        </div>
    );
}

function getBasicItems(data: CalendarComplianceBasic[], searchPath: string): BasicItemListItem[] {
    const items: BasicItemListItem[] = [];

    for (const compliance of data) {
        const editUrl = `${routeNames.editCalendar.path}/${encodeURI(compliance.name)}?id=${
            compliance.id
        }&${searchPath}`;
        const calendarUrl = getCalendarPath(compliance.id, compliance.name, compliance.type);

        items.push({
            key: compliance.name,
            name: (
                <CalendarNameView
                    name={compliance.name}
                    editUrl={editUrl}
                    calendarUrl={calendarUrl}
                />
            ),
            value: (
                <ComplianceStateBadge
                    compliant={compliance.compliant.compliant}
                    editUrl={editUrl}
                    calendarUrl={calendarUrl}
                />
            )
        });
    }

    return items;
}

function getCalendarPath(id: number, name: string, calendarType: CalendarTypeEnum) {
    const currentMonth = moment().startOf("month").unix();
    const currentWeek = moment().startOf("isoWeek").unix();

    const monthWeekQueryString = getMonthWeekQueryString(calendarType, currentWeek, currentMonth);

    return `${routeNames.calendar.path}/${encodeURI(name)}?id=${id}&${monthWeekQueryString}`;
}
