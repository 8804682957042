import React, {useContext, useEffect, useState} from "react";
import FullCalendar, {EventClickArg} from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, {DateClickArg} from "@fullcalendar/interaction";
import {useUserData} from "../../../../Hooks/useUserData";
import {useQuery} from "../../../../Hooks/useQuery";
import moment from "moment";
import {useHistory} from "react-router-dom";
import {StaffAccessLevel} from "../../../../../api/staff";
import {routeNames} from "../../../../Navigation/routeNames";
import {CalendarEventItem} from "../../../../../store/fullCalendarItemList/actions/FullCalendarItemListActionTypes";
import {CalendarContext} from "../../../../Context/CalendarContext";
import {toArray} from "../../../../../utils/sortingUtils";
import {renderEventContent} from "../../Helpers/calendarUtils";
import {usePageUrl} from "../../../../Hooks/usePageUrl";
import {getUserAccessLevel} from "../../../../../utils/userDataUtils";
import useMcConfig from "../../../../Hooks/useMcConfig";

const EventCalendar = (props: CalendarEventItem[]) => {
    const calendar = useContext(CalendarContext);
    const user = useUserData();
    const query = useQuery();
    const history = useHistory();
    const [epochDate, setEpochDate] = useState<number>(moment().unix());
    const {fullPath} = usePageUrl();
    const {config} = useMcConfig();

    //Full Calendar
    const calendarRef = React.createRef<any>();

    useEffect(() => {
        if (user.username.length === 0) return;
        if (!config.auth) return;
        const accessLevel = getUserAccessLevel(user, config);
        if (accessLevel !== StaffAccessLevel.SystemAdministrator) {
            history.replace(routeNames.notAuthorised.path);
            return;
        }
    }, [user, calendar, config]);

    /** Anytime the search parameters change, update the date */
    useEffect(() => {
        updateEpochTime();
    }, [window.location.search]);

    //Creating a new event
    const createNewEvent = (args: DateClickArg) => {
        const calendarApi = calendarRef.current.getApi();
        if (!calendar) {
            calendarApi.unselect();
            return;
        }

        const evtStartEpoch: number = new Date(args.date).getTime() / 1000;
        const path = `${routeNames.calendar.path}/${calendar.name}${routeNames.addCalendarEntry.path}`;
        localStorage.setItem("calendar_redirect", fullPath);

        history.push({
            pathname: path,
            search: `?startDate=${evtStartEpoch.toString()}&calendarId=${calendar.id.toString()}&month=${epochDate}`
        });
    };

    /** Views event */
    const viewEvent = (args: EventClickArg) => {
        if (!args.event.start) return;
        const calendarApi = calendarRef.current.getApi();
        if (!calendar) {
            calendarApi.unselect();
            return;
        }

        const path = `${routeNames.calendar.path}/${calendar.name}${routeNames.editCalendarEntry.path}/${args.event.id}`;
        localStorage.setItem("calendar_redirect", fullPath);
        history.push({
            pathname: path,
            search: `?calendarId=${calendar.id.toString()}&month=${epochDate}`
        });
    };

    /** Updates epoch time for calendar API */
    const updateEpochTime = () => {
        const date = query.get("month");
        if (!date) return;
        setEpochDate(+date);
    };

    /** Anytime the date changes, update calendar api */
    useEffect(() => {
        if (!calendarRef.current) return;
        const calendarApi = calendarRef.current.getApi();
        //FullCalendarApi works in ms, not s
        calendarApi.gotoDate(+epochDate * 1000);
    }, [epochDate]);

    return (
        <React.Fragment>
            <div className="calendar-wrapper">
                <div className="full-calendar-wrapper">
                    <FullCalendar
                        ref={calendarRef}
                        plugins={[dayGridPlugin, interactionPlugin]}
                        headerToolbar={{left: "", center: "", right: ""}}
                        initialView="dayGridMonth"
                        selectable={true}
                        firstDay={1}
                        eventOrder="color"
                        longPressDelay={500}
                        events={toArray(props)}
                        initialDate={epochDate * 1000}
                        eventContent={renderEventContent}
                        dateClick={createNewEvent}
                        eventClick={viewEvent}
                        height="auto"
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default EventCalendar;
