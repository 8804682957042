import React from "react";
import {CalendarEntry} from "../../../../../../api/grs";
import {formatUnixToDateTime} from "../../../../../../utils/momentUtils";
import FormHeader from "../../../../../Form/FormHeader";

const CalendarEntryHeader = (props: CalendarEntry) => (
    <FormHeader headerName={formatUnixToDateTime(props.startDate)} showAdvancedControl={true} />
);

export default CalendarEntryHeader;
