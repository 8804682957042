import {CalendarGroup, CalendarTypeEnum} from "../api/grs";
import moment from "moment";
import {routeNames} from "../components/Navigation/routeNames";

export function getGlobalCalendarUrlForGroup(group: CalendarGroup): string {
    const groupIdStr = group.id.toString();
    const urlParams = new URLSearchParams();

    urlParams.set("calendarGroup", groupIdStr);
    urlParams.set("id", groupIdStr);
    urlParams.set("name", group.name);
    urlParams.set("calendarIds", group.calendarIds.join(","));
    urlParams.set("isGlobalCalendar", "true");

    if (group.viewType === CalendarTypeEnum.Frontline) {
        const weekDate = moment().startOf("week");
        urlParams.set("week", weekDate.unix().toString());
        urlParams.set("isWeekView", "true");
        urlParams.set("viewType", "Frontline");
    } else {
        const monthDate = moment().startOf("month");
        urlParams.set("month", monthDate.unix().toString());
        urlParams.set("isWeekView", "false");
        urlParams.set("viewType", "Event");
    }

    return routeNames.globalCalendar.path + "/Global Calendar?" + urlParams.toString();
}
