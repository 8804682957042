import React, {useEffect} from "react";

import GrsDashContainer from "./Components/GrsDashContainer";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";

import {useDispatch} from "react-redux";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../Navigation/routeNames";
import {
    CalendarMonthView,
    getCalendarPathForGlobalStaffCalendar
} from "../AdminCalendar/Helpers/calendarUtils";
import {useXeroRoutes} from "../Xero/Hooks/useXeroRoutes";

const GroupRosteringSystem = () => {
    const dispatch = useDispatch();
    const {getSystemAdminRoutes} = useXeroRoutes();
    useEffect(() => {
        dispatch(setNavigationItems(getSystemAdminRoutes()));
    }, []);

    return <GrsDashContainer />;
};

export default GroupRosteringSystem;

export function getSystemAdminGroupedRoutes(): NavigationItem[] {
    return [
        {
            name: routeNames.groupRosteringSystem.name,
            path: routeNames.groupRosteringSystem.path
        },
        {
            name: CalendarMonthView.Global,
            path: getCalendarPathForGlobalStaffCalendar(CalendarMonthView.Global)
        },
        {
            name: "Shift Availability",
            children: [
                {
                    name: routeNames.availableShifts.name,
                    path: routeNames.availableShifts.path
                },
                {
                    name: routeNames.availableShiftsList.name,
                    path: routeNames.availableShiftsList.path
                }
            ]
        },
        {
            name: "Reports",
            children: [
                {
                    name: routeNames.staffReport.name,
                    path: routeNames.staffReport.path
                },
                {
                    name: routeNames.dutyManagerReportList.name,
                    path: routeNames.dutyManagerReportList.path
                }
            ]
        },
        {
            name: "Settings",
            superAdminOnly: true,
            children: [
                {
                    name: routeNames.addCalendar.name,
                    path: routeNames.addCalendar.path
                },
                {
                    name: routeNames.manageGlobalStaffBlock.name,
                    path: routeNames.manageGlobalStaffBlock.path
                },
                {
                    name: routeNames.manageCalendarGroups.name,
                    path: routeNames.manageCalendarGroups.path
                },
                {
                    name: routeNames.staffRota.name,
                    path: routeNames.staffRota.path
                }
            ]
        }
    ];
}
