import React from "react";

import {StaffAssignmentGrouping, StaffBlockSection} from "../../../../../../../api/grs";
import ReadOnlyCalendarEntryGroup from "../Group/ReadOnlyCalendarEntryGroup";
import FormHeader from "../../../../../../Form/FormHeader";

/** Shows the Calendar Entry section with actions */
const ReadOnlyCalendarEntrySection = (props: StaffBlockSection) => {
    return (
        <React.Fragment>
            <FormHeader headerName={props.name} />
            <div className="row mt-3 mr-0 ml-0 pb-3">
                {props.groupings.map((el: StaffAssignmentGrouping) => {
                    return (
                        <React.Fragment key={el.id}>
                            <div className="col-lg-3">
                                <ReadOnlyCalendarEntryGroup {...el} />
                            </div>
                        </React.Fragment>
                    );
                })}
            </div>
        </React.Fragment>
    );
};

export default ReadOnlyCalendarEntrySection;
