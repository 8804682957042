import React from "react";
import {useSelector} from "react-redux";
import {RootStore} from "../../../../../store/Store";
import {EventsReportEntry} from "../../../../../api/grs";
import moment from "moment";
import {getConfirmedStaffCountForEventReportEntry} from "../Helpers/eventReportHelpers";
import {Chart} from "react-google-charts";

export default function EventReportYearGraph() {
    const eventReportStore = useSelector((state: RootStore) => state.eventReport);
    if (
        !eventReportStore.data ||
        !eventReportStore.data.entries ||
        eventReportStore.data.entries.length === 0
    )
        return null;

    const chartData = getGoogleChartValues(eventReportStore.data.entries);
    const colours = ["#64c246", "#18c4fb"];
    const options = {
        legend: {
            position: "none"
        },
        annotations: {
            textStyle: {
                color: "#fff",
                fontSize: 18,
                bold: true
            }
        },
        hAxis: {
            gridlines: {
                count: 0
            },
            minorGridlines: {
                count: 0
            },
            textPosition: "none"
        },
        vAxis: {
            textStyle: {}
        },
        fontSize: 16,
        fontName: "Inter",
        colors: colours,
        lineWidth: 1,
        pointSize: 1,
        chartArea: {left: 50, width: "100%", height: "90%"}
    };

    return (
        <div className="dash-widget">
            <h2>YTD overview</h2>
            <div className="dash-widget-subtitle">Weekly analysis</div>

            <div className="dash-widget-chart-container">
                <Chart
                    chartType={"LineChart"}
                    data={chartData.chartData}
                    options={options}
                    width="100%"
                    height="350px"
                />
            </div>
            <div className="widget-inline-values">
                <div className="widget-inline-value">
                    <div className="dash-widget-dot" style={{backgroundColor: colours[0]}}></div>
                    <div className="widget-inline-value-name">Total assignments</div>
                    <div className="widget-inline-value-value">
                        {chartData.totalAssignments.toLocaleString()}
                    </div>
                </div>
                <div className="widget-inline-value">
                    <div className="dash-widget-dot" style={{backgroundColor: colours[1]}}></div>
                    <div className="widget-inline-value-name">Filled assignments</div>
                    <div className="widget-inline-value-value">
                        {chartData.totalFilled.toLocaleString()}
                    </div>
                </div>
            </div>
        </div>
    );
}

export type GoogleChartValues = [string, string | number, string | number];

interface EventReportWeekCount {
    weekNum: number;
    totalAssignments: number;
    confirmedStaff: number;
}

interface GoogleChartValuesResponse {
    chartData: GoogleChartValues[];
    totalAssignments: number;
    totalFilled: number;
}

function getGoogleChartValues(entries: EventsReportEntry[]): GoogleChartValuesResponse {
    const output: GoogleChartValues[] = [["Week", "Total Assignments", "Confirmed Staff"]];
    const interimValues = new Map<number, EventReportWeekCount>();
    let totalAssignments = 0;
    let totalFilled = 0;
    //const now = moment();

    for (const entry of entries) {
        const date = moment.unix(entry.startDate);
        const weekNum = date.isoWeek();

        let record = interimValues.get(weekNum);
        if (!record) {
            record = {
                weekNum: weekNum,
                totalAssignments: 0,
                confirmedStaff: 0
            };
            interimValues.set(weekNum, record);
        }

        const counts = getConfirmedStaffCountForEventReportEntry(entry);

        /*if (date < now && counts.confirmedCount < counts.totalAssignmentCount) {
            console.log("incomplete event", entry);
        }*/

        record.totalAssignments += counts.totalAssignmentCount;
        totalAssignments += counts.totalAssignmentCount;
        record.confirmedStaff += counts.confirmedCount;
        totalFilled += counts.confirmedCount;
    }

    const sortedValues = Array.from(interimValues.values()).sort((a, b) => a.weekNum - b.weekNum);
    for (const value of sortedValues) {
        output.push([
            "w/c " + moment().day("monday").isoWeek(value.weekNum).format("DD MMM YYYY"),
            value.totalAssignments,
            value.confirmedStaff
        ]);
    }

    return {
        chartData: output,
        totalAssignments,
        totalFilled
    };
}
