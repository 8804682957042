import {ClinicalGrade} from "../api/staff";

/** Splits a string by capital letter */
export function splitStringByCapitalLetter(value: string): string {
    const regex = /(?=[A-Z])+/g;
    //Split via capital letter regex.
    return value.split(regex).join(" ");
}

/** Capitalises the first letter of a string */
export function capitalizeFirstLetter(value: string): string {
    if (!value) return "";
    return value.charAt(0).toUpperCase() + value.slice(1);
}

/** lower cases the first letter of a string */
export function decapitalizeFirstLetter(value: string): string {
    return value.charAt(0).toLowerCase() + value.slice(1);
}

/** Removes any whitespace from a string */
export function removeWhitespaceFromString(value: string): string {
    return value.replace(/\s+/g, "");
}

/** Gets ui friendly name for an audit */
export function getUiFriendlyText(value: string): string {
    return splitStringByCapitalLetter(capitalizeFirstLetter(value));
}

export function getUiFriendlyTextWithSpaces(value: string): string {
    return value
        .split(" ")
        .map((newItem) => getUiFriendlyText(newItem))
        .join(" ");
}

export function getUiFriendlyClinicalGrade(value: ClinicalGrade): string {
    switch (value) {
        case ClinicalGrade.EAC:
            return "EAC";
        case ClinicalGrade.NewlyQualifiedParamedic1:
            return "Newly Qualified Paramedic 1";
        case ClinicalGrade.NewlyQualifiedParamedic2:
            return "Newly Qualified Paramedic 2";
        default:
            return getUiFriendlyText(value);
    }
}
