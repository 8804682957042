import React, {useEffect, useState} from "react";
import {CalendarEventItem} from "../../../../../../store/fullCalendarItemList/actions/FullCalendarItemListActionTypes";
import FullCalendar, {EventClickArg} from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import {toArray} from "../../../../../../utils/sortingUtils";
import moment from "moment";
import {renderEventContent} from "../../../../AdminCalendar/Helpers/calendarUtils";
import {useQuery} from "../../../../../Hooks/useQuery";
import {useUserData} from "../../../../../Hooks/useUserData";
import {StaffAccessLevel} from "../../../../../../api/staff";
import {
    canDutyManagerViewEvent,
    getViewPathForAdminForGlobalCalendar,
    getViewPathForDutyManagerForGlobalCalendar
} from "../../../Helpers/globalCalendarHelpers";
import {useHistory} from "react-router-dom";
import {usePageUrl} from "../../../../../Hooks/usePageUrl";
import {getUserAccessLevel} from "../../../../../../utils/userDataUtils";
import useMcConfig from "../../../../../Hooks/useMcConfig";

const GlobalMonthViewCalendar = (props: CalendarEventItem[]) => {
    const [epochDate, setEpochDate] = useState<number>(moment().unix());
    const user = useUserData();
    const query = useQuery();
    const history = useHistory();
    const {fullPath} = usePageUrl();
    const {config} = useMcConfig();
    //Full Calendar
    const calendarRef = React.createRef<any>();
    /** Views event */
    const viewEvent = (args: EventClickArg) => {
        if (!args.event.start) return;
        if (user.username.length === 0) return;
        const calendarApi = calendarRef.current.getApi();
        const accessLevel = getUserAccessLevel(user, config);
        switch (accessLevel) {
            case StaffAccessLevel.Staff:
                //Staff cannot view event
                calendarApi.unselect();
                return;
            case StaffAccessLevel.DutyManager:
                handleDutyManagerClickAction(args);
                break;
            case StaffAccessLevel.SystemAdministrator:
                // Can see the event as normal
                handleAdminClickAction(args);
                return;
        }
    };
    const handleAdminClickAction = (args: EventClickArg) => {
        const path = getViewPathForAdminForGlobalCalendar(args, epochDate);
        localStorage.setItem("calendar_redirect", fullPath);
        history.push(path);
    };
    const handleDutyManagerClickAction = (args: EventClickArg) => {
        //Can only view events they are on and see a read only version
        const canView = canDutyManagerViewEvent(args, user.username);
        if (!canView) return;
        const path = getViewPathForDutyManagerForGlobalCalendar(args, epochDate);
        localStorage.setItem("calendar_redirect", fullPath);
        history.push(path);
    };

    /** Anytime the search parameters change, update the date */
    useEffect(() => {
        updateEpochTime();
    }, [window.location.search]);

    /** Updates epoch time for calendar API */
    const updateEpochTime = () => {
        const date = query.get("month");
        if (!date) return;
        setEpochDate(+date);
    };

    /** Anytime the date changes, update calendar api */
    useEffect(() => {
        if (!calendarRef.current) return;
        const calendarApi = calendarRef.current.getApi();
        //FullCalendarApi works in ms, not s
        calendarApi.gotoDate(+epochDate * 1000);
    }, [epochDate]);

    return (
        <React.Fragment>
            <div className="calendar-wrapper">
                <div className="full-calendar-wrapper">
                    <FullCalendar
                        ref={calendarRef}
                        plugins={[dayGridPlugin, interactionPlugin]}
                        headerToolbar={{left: "", center: "", right: ""}}
                        initialView="dayGridMonth"
                        selectable={true}
                        firstDay={1}
                        eventOrder="color"
                        longPressDelay={500}
                        events={toArray(props)}
                        initialDate={epochDate * 1000}
                        eventContent={renderEventContent}
                        eventClick={viewEvent}
                        height="auto"
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default GlobalMonthViewCalendar;
