import React from "react";
import DatePicker from "react-datepicker";
import {GrsChangeEvent} from "../../../../../../utils/filterUtils";
import DatePickerInputButton from "../../../../../Button/DatePickerInputButton";
import CalendarGroupDropdown from "../../../../../Dropdown/Components/CalendarGroupDropdown";
import AuthAmI from "../../../../../AuthAmI/AuthAmI";
import {StaffAccessLevel} from "../../../../../../api/staff";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../Button/MCButton";
import {formatUnixToDDMMYYYY} from "../../../../../../utils/momentUtils";
import {isSameISOWeek} from "date-fns";
import {useGlobalCalendarWeekFilters} from "./Hooks/useGlobalCalendarWeekFilters";
import {CalendarEntryListRequestWithViewType} from "../GlobalCalendarDesktop";

const GlobalCalendarWeekFilters = (props: GrsChangeEvent<CalendarEntryListRequestWithViewType>) => {
    const {
        startDate,
        groupId,
        onDateChanged,
        onGroupChanged,
        previousWeekButtonText,
        goToPreviousWeek,
        nextWeekButtonText,
        goToNextWeek
    } = useGlobalCalendarWeekFilters(props);

    /** Gets the date picker format for the iso week */
    const getIsoWeekPickerDateFormat = (): string | string[] | undefined => {
        if (!startDate) return [];
        const startOfIsoWeek = startDate.clone().startOf("isoWeek").unix();
        const endOfIsoWeek = startDate.clone().endOf("isoWeek").unix();

        return `${formatUnixToDDMMYYYY(startOfIsoWeek)} - ${formatUnixToDDMMYYYY(endOfIsoWeek)}`;
    };

    /** Gets the iso week class name */
    const getIsoWeekDayClassName = (dateLeft: Date, dateRight: Date) => {
        return isSameISOWeek(dateLeft, dateRight) ? "react-datepicker__day--selected" : "";
    };

    return (
        <React.Fragment>
            <table className="filters-table mt-3">
                <tbody className="filters-lg">
                    <tr>
                        <th className="filter-heading w-50 pl-3 pr-3">Select Date</th>
                        <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                            <th className="filter-heading w-50 pl-3 pr-3">Groups</th>
                        </AuthAmI>
                    </tr>
                    <tr>
                        <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                            {startDate && (
                                <DatePicker
                                    selected={startDate.toDate()}
                                    onChange={onDateChanged}
                                    dateFormat={getIsoWeekPickerDateFormat()}
                                    dayClassName={(date: Date) =>
                                        getIsoWeekDayClassName(date, startDate.toDate())
                                    }
                                    calendarStartDay={1}
                                    portalId="root-portal"
                                    customInput={<DatePickerInputButton />}
                                />
                            )}
                        </td>
                        <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                            <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                                <CalendarGroupDropdown
                                    onChange={onGroupChanged}
                                    groupId={groupId}
                                />
                            </td>
                        </AuthAmI>
                    </tr>
                    <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                        <tr>
                            <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                                <MCButton
                                    size={ButtonSize.Large}
                                    innerValue={previousWeekButtonText()}
                                    onClick={goToPreviousWeek}
                                    colour={ButtonColourOptions.DarkBlue}
                                    roundedCorner
                                />
                            </td>
                            <td align={"right"} className="filter-heading w-50 pl-3 pr-3 pb-2">
                                <MCButton
                                    size={ButtonSize.Large}
                                    innerValue={nextWeekButtonText()}
                                    onClick={goToNextWeek}
                                    colour={ButtonColourOptions.DarkBlue}
                                    roundedCorner
                                />
                            </td>
                        </tr>
                    </AuthAmI>
                </tbody>
                <tbody className="filters-md">
                    <tr>
                        <th className="filter-heading w-50 pl-3 pr-3">Select Date</th>
                        <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                            <th className="filter-heading w-50 pl-3 pr-3">Groups</th>
                        </AuthAmI>
                    </tr>
                    <tr>
                        <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                            {startDate && (
                                <DatePicker
                                    selected={startDate.toDate()}
                                    onChange={onDateChanged}
                                    dateFormat={getIsoWeekPickerDateFormat()}
                                    dayClassName={(date: Date) =>
                                        getIsoWeekDayClassName(date, startDate.toDate())
                                    }
                                    calendarStartDay={1}
                                    portalId="root-portal"
                                    customInput={<DatePickerInputButton />}
                                />
                            )}
                        </td>
                        <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                            <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                                <CalendarGroupDropdown
                                    onChange={onGroupChanged}
                                    groupId={groupId}
                                />
                            </td>
                        </AuthAmI>
                    </tr>
                    <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                        <tr>
                            <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                                <MCButton
                                    size={ButtonSize.Large}
                                    innerValue={previousWeekButtonText()}
                                    onClick={goToPreviousWeek}
                                    colour={ButtonColourOptions.DarkBlue}
                                    roundedCorner
                                />
                            </td>
                            <td align={"right"} className="filter-heading w-50 pl-3 pr-3 pb-2">
                                <MCButton
                                    size={ButtonSize.Large}
                                    innerValue={nextWeekButtonText()}
                                    onClick={goToNextWeek}
                                    colour={ButtonColourOptions.DarkBlue}
                                    roundedCorner
                                />
                            </td>
                        </tr>
                    </AuthAmI>
                </tbody>
                <tbody className="filters-sm">
                    <tr>
                        <th className="filter-heading w-100 pl-3 pr-3">Select Date</th>
                    </tr>
                    <tr>
                        <td className="filter-heading w-100 pl-3 pr-3 pb-2">
                            {startDate && (
                                <DatePicker
                                    selected={startDate.toDate()}
                                    onChange={onDateChanged}
                                    dateFormat={getIsoWeekPickerDateFormat()}
                                    dayClassName={(date: Date) =>
                                        getIsoWeekDayClassName(date, startDate.toDate())
                                    }
                                    calendarStartDay={1}
                                    portalId="root-portal"
                                    customInput={<DatePickerInputButton />}
                                />
                            )}
                        </td>
                    </tr>
                    <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                        <tr>
                            <th className="filter-heading w-50 pl-3 pr-3">Groups</th>
                        </tr>
                        <tr>
                            <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                                <CalendarGroupDropdown
                                    onChange={onGroupChanged}
                                    groupId={groupId}
                                />
                            </td>
                        </tr>
                    </AuthAmI>
                    <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                        <tr>
                            <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                                <MCButton
                                    size={ButtonSize.Large}
                                    innerValue={previousWeekButtonText()}
                                    onClick={goToPreviousWeek}
                                    colour={ButtonColourOptions.DarkBlue}
                                    roundedCorner
                                />
                            </td>
                            <td align={"right"} className="filter-heading w-50 pl-3 pr-3 pb-2">
                                <MCButton
                                    size={ButtonSize.Large}
                                    innerValue={nextWeekButtonText()}
                                    onClick={goToNextWeek}
                                    colour={ButtonColourOptions.DarkBlue}
                                    roundedCorner
                                />
                            </td>
                        </tr>
                    </AuthAmI>
                </tbody>
            </table>
        </React.Fragment>
    );
};

export default GlobalCalendarWeekFilters;
