import React, {useContext, useEffect} from "react";
import {CalendarEntryContext} from "../../../../../../../Context/CalendarEntryContext";
import {useDispatch, useSelector} from "react-redux";
import {Modal, useModal} from "pulse-modal";
import {RootStore} from "../../../../../../../../store/Store";
import {getUserInterestsForCalendarEntry} from "../../../../../../../../store/userInterest/actions/UserInterestListActions";
import MCButtonWithIndicator from "../../../../../../../Button/MCButtonWithIndicator";
import {ButtonColourOptions, ButtonSize} from "../../../../../../../Button/MCButton";
import {WithServiceState} from "store-fetch-wrappers/dist";
import InterestedStaffListForEntry from "./Components/InterestedStaffListForEntry";

const ServiceWrapper = WithServiceState(InterestedStaffListForEntry);

const InterestedStaffButton = () => {
    const entry = useContext(CalendarEntryContext);
    const dispatch = useDispatch();
    const {isShown, toggle} = useModal();
    const userInterestStore = useSelector((state: RootStore) => state.userInterest);
    const userInterestCount =
        useSelector((state: RootStore) => state.userInterest).data?.length || 0;

    useEffect(() => {
        dispatch(getUserInterestsForCalendarEntry(entry.id));
    }, []);
    return (
        <React.Fragment>
            <div className="d-inline-block mr-3">
                <MCButtonWithIndicator
                    numberBubbleValue={userInterestCount}
                    size={ButtonSize.Large}
                    innerValue={"Interested Staff"}
                    onClick={toggle}
                    colour={ButtonColourOptions.DarkBlue}
                    roundedCorner
                />
                <Modal
                    modalSize={"xl"}
                    title={"Interested Staff"}
                    bodyChildren={
                        <React.Fragment>
                            <ServiceWrapper
                                {...userInterestStore}
                                loaderWheelType={"three-ring"}
                                loaderType={"overlay"}
                                showLoadingText={true}
                                placeholderBlockCount={1}
                            />
                        </React.Fragment>
                    }
                    onClose={toggle}
                    isShown={isShown}
                />
            </div>
        </React.Fragment>
    );
};

export default InterestedStaffButton;
