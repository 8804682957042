import {ClinicalGrade} from "../api/grs";
import {GenericNameValue} from "./sortingUtils";

export const BriefingDocumentPriorDays = 5;
export const BriefingDocumentPriorSeconds = 86400 * BriefingDocumentPriorDays;

/** Generates UI friendly clinical grade options based on the displayed Clinical Grade Enum */
export function getClinicalGradeOptions() {
    const clinicalGrades = Object.keys(ClinicalGrade);
    const options: GenericNameValue[] = [];
    const regex = /(?=[A-Z])+/g;

    for (const grade of clinicalGrades) {
        const displayedState = grade.split(regex).join(" ");

        if (displayedState === "E A C") {
            options.push({
                name: "Emergency Ambulance Crew",
                value: grade
            });
        } else {
            options.push({
                name: displayedState,
                value: grade
            });
        }
    }
    return options;
}

/** Returns if the current date is within the briefing document threshold of the event date provided */
export function inBriefingDocumentWindow(eventDate: number): boolean {
    const threshold = eventDate - BriefingDocumentPriorSeconds;
    const now = new Date().getTime() / 1000;
    return now > threshold;
}
