import React, {useContext} from "react";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../Button/MCButton";
import {Modal, useModal} from "pulse-modal";
import SMSForm from "./SMSForm";
import {useDispatch} from "react-redux";
import {nullifySMSStore, setSMSEventStaffMembers} from "../../../store/sms/actions/SMSActions";
import {CalendarEntryContext} from "../../Context/CalendarEntryContext";

const SMSButton = () => {
    const {toggle, isShown} = useModal();
    const dispatch = useDispatch();
    const entry = useContext(CalendarEntryContext);

    const onModalClosed = () => {
        toggle();
        dispatch(nullifySMSStore());
    };

    const onModalOpened = () => {
        toggle();
        dispatch(setSMSEventStaffMembers(entry));
    };

    return (
        <React.Fragment>
            <MCButton
                size={ButtonSize.Large}
                innerValue={"Send SMS message"}
                onClick={onModalOpened}
                colour={ButtonColourOptions.LightBlue}
                roundedCorner
                className={"mr-3"}
            />
            <Modal
                modalSize={"xl"}
                title={"Send SMS message"}
                bodyChildren={<SMSForm onClose={onModalClosed} />}
                onClose={onModalClosed}
                isShown={isShown}
            />
        </React.Fragment>
    );
};

export default SMSButton;
