import React from "react";

const BackgroundContainer = ({children, background, fullPage}: BackgroundContainerProps) => {
    return (
        <div className={`${background} ${fullPage ? "full-page-bg" : ""}`}>
            <div className={`background-overlay ${fullPage ? "full-page-bg" : ""}`}>
                <div className="page-container">{children}</div>
            </div>
        </div>
    );
};

export default BackgroundContainer;

interface BackgroundContainerProps {
    children: React.ReactNode;
    background: Backgrounds;
    pageContainerClass?: string;
    fullPage?: boolean;
}

type Backgrounds = "background-chevrons" | "background-stars" | "background-clear";
