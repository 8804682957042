import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {routeNames} from "../../../Navigation/routeNames";
import {setNavigationItems} from "../../../../store/navigation/actions/NavigationActions";
import {useUserData} from "../../../Hooks/useUserData";
import {StaffAccessLevel, UserData} from "../../../../api/staff";
import {RootStore} from "../../../../store/Store";
import {
    CalendarMonthView,
    getCalendarPathForGlobalStaffCalendar
} from "../../AdminCalendar/Helpers/calendarUtils";
import {WithServiceState} from "store-fetch-wrappers/dist";
import StaffReportTable from "./Components/StaffReportTable";
import {PlatformOS, usePlatform} from "../../../Hooks/usePlatform";
import {NavigationItem} from "../../../../store/navigation/actions/NavigationActionTypes";
import {useXeroRoutes} from "../../Xero/Hooks/useXeroRoutes";
import useMcConfig from "../../../Hooks/useMcConfig";
import {getUserAccessLevel} from "../../../../utils/userDataUtils";
const ServiceWrapper = WithServiceState(StaffReportTable);

/** Parent component for the staff report */
export default function StaffReport() {
    const dispatch = useDispatch();
    const user = useUserData();
    const platform = usePlatform();
    const staffReportStore = useSelector((state: RootStore) => state.staffReport);
    const {getSystemAdminRoutes} = useXeroRoutes();
    const {config} = useMcConfig();

    /** Page setup based on logged in user */
    useEffect(() => {
        if (user.username.length === 0) return;
        dispatch(setNavigationItems(getNavItemsForStaffReport(user, platform)));
    }, [user]);

    function getNavItemsForStaffReport(targetUser: UserData, targetPlatform: PlatformOS) {
        const userAccessLevel = getUserAccessLevel(targetUser, config);
        switch (userAccessLevel) {
            case StaffAccessLevel.SystemAdministrator:
                return getSystemAdminRoutes();
            case StaffAccessLevel.DutyManager:
            case StaffAccessLevel.Staff:
                return [
                    {
                        name: CalendarMonthView.Staff,
                        path: getCalendarPathForGlobalStaffCalendar(CalendarMonthView.Staff)
                    },
                    {
                        name: routeNames.staffReport.name,
                        path: routeNames.staffReport.path
                    },
                    {
                        name: routeNames.pendingNotifications.name,
                        path: routeNames.pendingNotifications.path
                    },
                    {
                        name: routeNames.availableShiftsList.name,
                        path: routeNames.availableShiftsList.path
                    },
                    ...getAvailableShiftsListRoute(targetPlatform)
                ];
        }
    }

    return (
        <React.Fragment>
            <div className="page-container">
                <ServiceWrapper
                    showLoadingText={true}
                    placeholderBlockCount={1}
                    loaderType={"overlay"}
                    loaderWheelType={"three-ring"}
                    {...staffReportStore}
                />
            </div>
        </React.Fragment>
    );
}

export function getAvailableShiftsListRoute(platform: PlatformOS): NavigationItem[] {
    switch (platform) {
        case PlatformOS.Desktop:
            return [
                {
                    name: routeNames.availableShifts.name,
                    path: routeNames.availableShifts.path
                }
            ];
        default:
            return [];
    }
}
