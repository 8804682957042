import React, {ChangeEvent, useContext} from "react";
import Toggle, {ToggleSize} from "../Toggle/Toggle";
import {ShowAdvancedControlsContext} from "../../contexts/advancedControls";

export default function FormHeader(props: FormHeaderProps) {
    const showAdvancedContext = useContext(ShowAdvancedControlsContext);
    const showAdvanced = showAdvancedContext?.show === true;

    function onToggleChange(e: ChangeEvent<HTMLInputElement>) {
        showAdvancedContext?.setShow(e.target.checked);
    }

    return (
        <div className="mc-form-heading">
            <div className="col">
                <h5 className={`mb-0 ${props.className || ""}`}>{props.headerName}</h5>
            </div>
            {props.showAdvancedControl && (
                <div className="mc-form-heading-controls">
                    <Toggle
                        checked={showAdvanced}
                        onChange={onToggleChange}
                        size={ToggleSize.Small}
                    />
                    Show advanced options
                </div>
            )}
        </div>
    );
}

interface FormHeaderProps {
    headerName: string;
    className?: string;
    showAdvancedControl?: boolean;
}
