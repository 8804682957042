export const routeNames = {
    groupRosteringSystem: {
        path: "/event-calendar",
        name: "GRS Scheduling Dashboard"
    },
    staffReport: {
        name: "Staff Reports",
        path: "/reports/staff-report"
    },
    wageReport: {
        name: "Wage Reports",
        path: "/reports/wage-report"
    },
    eventReport: {
        name: "EMS Capacity Review",
        path: "/reports/event-report"
    },
    editCalendar: {
        path: "/edit-calendar",
        name: "Edit Calendar"
    },
    addCalendar: {
        path: "/add-calendar",
        name: "Add Calendar"
    },
    calendar: {
        name: "Calendar",
        path: "/calendar"
    },
    staffRota: {
        name: "Staff Rota",
        path: "/staff-rota"
    },
    globalCalendar: {
        name: "Calendar",
        path: "/global-calendar"
    },
    addCalendarEntry: {
        path: "/add-calendar-entry",
        name: "Add Calendar Entry"
    },
    editCalendarEntry: {
        path: "/edit-calendar-entry",
        name: "Edit Calendar Entry"
    },
    readOnlyEntry: {
        path: "/resource-overview",
        name: "Entry Overview"
    },
    addVenue: {
        path: "/venue/add-venue",
        name: "Add Venue"
    },
    editVenues: {
        path: "/venue/edit-venues",
        name: "Edit Venues"
    },
    venueList: {
        path: "/venue/list",
        name: "Venues"
    },
    attendanceOverview: {
        path: "/attendance/overview",
        name: "Attendance"
    },
    attendanceRegister: {
        path: "/attendance/register",
        name: "Attendance Register"
    },
    dutyManagerReportList: {
        path: "/duty-manager-reports",
        name: "Duty Manager Reports"
    },
    createDutyManagerReport: {
        path: "/duty-manager-reports/new",
        name: "New Report"
    },
    editDutyManagerReport: {
        path: "/duty-manager-reports/edit",
        name: "Edit Report"
    },
    viewDutyManagerReport: {
        path: "/duty-manager-reports/view",
        name: "View Report"
    },
    availableShifts: {
        name: "Available Shifts",
        path: "/available-shifts"
    },
    availableShiftsList: {
        name: "Shift Interest List",
        path: "/shift-interest-list"
    },
    pendingNotifications: {
        name: "Notifications",
        path: "/notifications"
    },
    confirmAttendance: {
        name: "Event Attendance",
        path: "/attendance/confirm"
    },
    acceptBriefing: {
        name: "Accept Briefing",
        path: "/attendance/briefingconfirm"
    },
    manageGlobalStaffBlock: {
        path: "/staff-block/manage",
        name: "Manage Staff Blocks"
    },
    addGlobalStaffBlock: {
        path: "/staff-block/add",
        name: "Add Staff Blocks"
    },
    editGlobalStaffBlock: {
        path: "/staff-block/edit",
        name: "Edit Staff Blocks"
    },
    manageCalendarGroups: {
        path: "/calendar-groups/manage",
        name: "Manage Calendar Groups"
    },
    addCalendarGroups: {
        path: "/calendar-groups/add",
        name: "Add Calendar Group"
    },
    editCalendarGroups: {
        path: "/calendar-groups/edit",
        name: "Edit Calendar Group"
    },
    //XERO
    xeroLogin: {
        path: "/xero",
        name: "Xero Invoicing"
    },
    xeroCallback: {
        path: "/xero/callback",
        name: "Xero Invoicing"
    },
    xeroInvoices: {
        path: "/xero/invoices",
        name: "Xero Invoicing"
    },
    //Auth pages
    login: {
        path: "/login",
        name: "",
        icon: ""
    },
    loggedin: {
        path: "/loggedin",
        name: "",
        icon: ""
    },
    logout: {
        path: "/logout",
        name: "",
        icon: ""
    },
    inActive: {
        path: "/inactive",
        name: "",
        icon: ""
    },
    notAuthorised: {
        path: "/not-authorised",
        name: "",
        icon: ""
    },
    notFound: {
        path: "/not-found",
        name: "",
        icon: ""
    }
};
